import React, { useState } from 'react';
import { ITopic } from '../types';
import Columns from './Columns';
import MostPopular from './MostPopular';
import News from './News';
import Topics from './Topics';
import TopSection from './TopSection';

interface Props {}

const Home = (props: Props) => {
  const [topics, setTopics] = useState<ITopic[]>([
    {
      id: '1',
      name: 'Pharmacy',
      selected: false,
    },
    {
      id: '2',
      name: 'Surgery',
      selected: false,
    },
    {
      id: '3',
      name: 'Radiology',
      selected: false,
    },
    {
      id: '4',
      name: 'Medical Books',
      selected: false,
    },
    {
      id: '5',
      name: 'Disease Monographs',
      selected: false,
    },
    {
      id: '6',
      name: 'Pharmacy',
      selected: false,
    },
    {
      id: '7',
      name: 'Surgery',
      selected: false,
    },
    {
      id: '8',
      name: 'Radiology',
      selected: false,
    },
    {
      id: '9',
      name: 'Medicine and Law',
      selected: true,
    },
    {
      id: '10',
      name: 'Covid-19',
      selected: true,
    },
    {
      id: '11',
      name: 'Medical Books',
      selected: false,
    },
    {
      id: '12',
      name: 'Disease Monographs',
      selected: false,
    },
    {
      id: '13',
      name: 'Pharmacy',
      selected: false,
    },
    {
      id: '14',
      name: 'Surgery',
      selected: false,
    },
    {
      id: '15',
      name: 'Radiology',
      selected: false,
    },
  ]);

  return (
    <div>
      <TopSection />
      <Topics topics={topics} setTopics={setTopics} />
      <MostPopular />
      <Columns />
      <News />
    </div>
  );
};

export default Home;
