import axios from 'axios';
import webConfig from '../config/config';
import { logOut } from '../context/actions/auth';

// https://cors-anywhere.herokuapp.com/ 

const axiosInstance = axios.create({ baseURL: `${webConfig.baseUrl}` });

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const response = await err.response;

    if (response.status === 401) logOut();

    return Promise.reject(err);
  }
);

export default axiosInstance;
