import React, { ChangeEvent, useState } from 'react';
import {
  Container,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../media/icons/search.svg';
import { ReactComponent as ChevronDownIcon } from '../../media/icons/chevron-down-filled.svg';

interface Props {}

const Studies = (props: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  const SearchInput = withStyles({
    root: {
      height: '4rem',
      fontSize: deviceXs ? '1rem' : '1.5rem',
      fontWeight: 'bold',
      '& input::placeholder': {
        color: 'rgba(44, 54, 79, 0.4)',
      },
      '& input': {
        height: '4rem',
        padding: deviceXs ? '0 1rem' : '0 2rem',
      },
    },
  })(OutlinedInput);

  const ResourceSelect = withStyles({
    root: {
      minWidth: deviceXs ? 0 : '7rem',
    },
  })(Select);

  const [searchTag, setSearchTag] = useState<string>('');
  const [topic, setTopic] = useState<string>('All Resources');

  const topics = ['All Resources', 'Resource 1', 'Resource 2', 'Resource 3'];

  return (
    <div className='section__dark'>
      <Container maxWidth='lg'>
        <div className='section_title-wrapper'>
          <h3 className='section_title'>Clinical studies / trials</h3>
        </div>
        <SearchInput
          type='text'
          fullWidth
          inputProps={{ autocomplete: 'off' }}
          value={searchTag}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTag(e.target.value)}
          placeholder='Search for Study / Trial...'
          startAdornment={
            <InputAdornment
              position='start'
              style={{ borderRight: '1px solid rgba(44, 54, 79, 0.1)', marginRight: 0, paddingRight: '1rem' }}
            >
              <div>
                <ResourceSelect
                  disableUnderline
                  value={topic}
                  onChange={(e: ChangeEvent<{ value: unknown }>) => setTopic(e.target.value as string)}
                  style={{ border: 'none', fontSize: '1rem' }}
                  IconComponent={ChevronDownIcon}
                >
                  {topics.map((topic) => (
                    <MenuItem value={topic}>{topic}</MenuItem>
                  ))}
                </ResourceSelect>
              </div>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position='end'>
              <SearchIcon className='icon-button__dark' style={{ width: '1.5rem', height: '1.5rem' }} />
            </InputAdornment>
          }
        />
      </Container>
    </div>
  );
};

export default Studies;
