import React, { MouseEvent } from 'react';
import { Avatar, Chip, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { IArticle } from '../types';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';
import { useHistory } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';
import HubCard from './HubCard';

interface Props {
  article: IArticle;
  displayGrid?: boolean;
}

const ArticleCard = ({ article, displayGrid }: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const convertDate = (unixTimestamp: string | number) => {
    let date =
      typeof unixTimestamp === 'string' ? new Date(parseInt(unixTimestamp, 10) * 1000) : new Date(unixTimestamp * 1000);
    let dd: number | string = date.getDate();
    let mm: number | string = date.getMonth() + 1;

    return `${
      mm === 1
        ? 'Jan'
        : mm === 2
        ? 'Feb'
        : mm === 3
        ? 'Mar'
        : mm === 4
        ? 'Apr'
        : mm === 5
        ? 'May'
        : mm === 6
        ? 'Jun'
        : mm === 7
        ? 'Jul'
        : mm === 8
        ? 'Aug'
        : mm === 9
        ? 'Sep'
        : mm === 10
        ? 'Oct'
        : mm === 11
        ? 'Nov'
        : mm === 12
        ? 'Dec'
        : null
    } ${dd}`;
  };

  const addBookmark = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Added to bookmarks');
  };

  const handleAudio = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div onClick={() => history.push(article.audio ? '/audio/id' : '/articles/id')}>
      {!displayGrid && (
        <>
          {article.author && (
            <div className='article-card_label-wrapper'>
              <span className='article_label'>
                {article.author} <span className='article-card_text-secondary'>in</span> {article.area}
              </span>
            </div>
          )}
          {article.tags && (
            <div style={{ margin: '0.5rem 0' }}>
              {article.tags.map((tag: string, key) => (
                <Chip key={key} color='primary' label={tag} size='small' style={{ marginRight: '0.5rem' }} />
              ))}
            </div>
          )}
        </>
      )}
      <div className='article-card-wrapper'>
        {article.avatar && (
          <Avatar src={article.avatar} style={{ width: '4rem', height: '4rem', marginRight: '0.5rem' }} />
        )}
        <div>
          {displayGrid && article.author && (
            <div className='article-card_label-wrapper'>
              <span className='article-card_label'>
                {article.author} <span className='article-card_text-secondary'>in</span> {article.area}
              </span>
            </div>
          )}
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={displayGrid && (article.image || article.video) ? 8 : 12}
              lg={displayGrid && (article.image || article.video) ? 9 : 12}
            >
              <div>
                {displayGrid && article.tags && (
                  <div style={{ margin: '0.5rem 0' }}>
                    {article.tags.map((tag: string, key) => (
                      <Chip key={key} color='primary' label={tag} size='small' style={{ marginRight: '0.5rem' }} />
                    ))}
                  </div>
                )}
                {article.logo && (
                  <div style={{ marginBottom: '0.5rem' }}>
                    <img src={article.logo} alt='' />
                  </div>
                )}
                <div className='article-card_title-wrapper'>
                  <h4 className='article-card_title'>{article.title}</h4>
                </div>
                {article.text && <p className='article-card_text'>{article.text}</p>}
                {article.audio && (
                  <div style={{ marginBottom: '0.5rem', maxWidth: '100%' }} onClick={handleAudio}>
                    {/* <AudioPlaceholder /> */}
                    {/* <img src={audio} alt='' /> */}
                    <AudioPlayer />
                  </div>
                )}
                {!displayGrid && article.image && (
                  <div style={{ width: '100%', maxWidth: '100%' }}>
                    <img
                      src={article.image}
                      alt=''
                      style={{ width: '100%', maxWidth: '100%', maxHeight: '18rem', marginBottom: '1rem' }}
                    />
                  </div>
                )}
                <div className='article-card_info-wrapper'>
                  <span className='article-card_text-secondary'>
                    {convertDate(article.date)} &middot; {article.estimate} mins to read{' '}
                    <span className='article-card_button' onClick={addBookmark}>
                      <span style={{ color: 'rgba(44, 54, 79, 0.5)' }}>&middot;</span> <BookmarkIcon /> Add to bookmarks
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
            {displayGrid && (article.image || article.video) && !deviceSm && (
              <Grid item xs={4} lg={3}>
                {article.video ? (
                  <HubCard
                    hub={{
                      title: 'Diagnostic',
                      contentTitle: 'Sustained Remission Achieved from Anti-CD19',
                      contentSubtitle: 'Bachanov et al.',
                      video: true,
                    }}
                    large={false}
                  />
                ) : (
                  <img
                    src={article.image}
                    alt=''
                    className='article_image'
                    style={{ width: '100%', maxWidth: '100%' }}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
