import React, { ChangeEvent, Fragment, useState } from 'react';
import {
  Checkbox,
  Container,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { IArticle } from '../types';
import _ from 'lodash';

import { ReactComponent as CloseIcon } from '../../media/icons/cross.svg';
import { ReactComponent as CheckedIcon } from '../../media/icons/checked.svg';
import { ReactComponent as CheckboxBlankIcon } from '../../media/icons/checkbox-blank.svg';

import doctor1 from '../../media/placeholders/doctor-glasses.png';
import doctor2 from '../../media/placeholders/female-doctor.png';
import doctor3 from '../../media/placeholders/female-doctor1.png';
import colonoscopy from '../../media/placeholders/colonoscopy.png';
import doctor from '../../media/placeholders/doctor.png';
import protectiveWear from '../../media/placeholders/protective-wear.png';
import logo1 from '../../media/placeholders/logo1.png';
import logo2 from '../../media/placeholders/logo2.png';
import logo3 from '../../media/placeholders/logo3.png';
import logo4 from '../../media/placeholders/logo4.png';
import pharmacy from '../../media/placeholders/pharmacy.png';
import chart from '../../media/placeholders/chart.png';
import chart1 from '../../media/placeholders/chart2.png';
import drugDoctor from '../../media/placeholders/drug-doctor.png';
import ArticleCard from '../reusable/ArticleCard';

interface Props {
  match: any;
}

const Search = ({ match }: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const deviceMd = useMediaQuery(theme.breakpoints.down('md'));

  const searchTag = match.params.tag;

  const [results, setResults] = useState<IArticle[]>([
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo!',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem harum voluptas sint provident? Ratione, modi?',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus beatae minima a eligendi esse optio voluptatum commodi, at accusamus dolorem, possimus dignissimos officiis!',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia fuga vero consequatur assumenda, aperiam incidunt necessitatibus maxime nesciunt!',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eius facilis neque quidem officiis id maxime porro, quia dicta soluta tempora. Voluptatem.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias ab aspernatur nostrum recusandae incidunt, fugiat magnam amet vel dolorum magni et commodi sunt quas, possimus, maiores distinctio obcaecati dolorem.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Non error voluptates aspernatur excepturi recusandae nulla sit amet, explicabo ea inventore assumenda expedita.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet perferendis quas eos iste cumque asperiores odio earum, vitae placeat reprehenderit illo quibusdam eveniet? Ut, dolores.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel suscipit nobis impedit nisi?',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum, minus. Eaque deserunt dolore laborum, minima non similique!',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam vel maxime animi voluptates in impedit, perferendis distinctio magni quam aut?',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur numquam perferendis sed eveniet aliquam esse voluptate recusandae aliquid possimus.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde nostrum dolores nulla neque atque cumque vel optio aliquam dicta. Nisi est officia ad?',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: doctor1,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: doctor2,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      audio: true,
      avatar: doctor3,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      audio: true,
      avatar: doctor2,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: doctor2,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      audio: true,
      avatar: doctor1,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
    {
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      logo: logo1,
      image: pharmacy,
    },
    {
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      logo: logo2,
      image: chart,
    },
    {
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      logo: logo3,
      image: chart1,
    },
    {
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      logo: logo4,
      image: drugDoctor,
    },
  ]);

  const [filters, setFilters] = useState<any>({ area: [], author: [], content: [] });

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    let section = filters[e.target.name];
    const index = section.indexOf(e.target.value);
    if (index > -1) {
      section.splice(index, 1);
      setFilters({ ...filters, [e.target.name]: [...section] });
    } else {
      setFilters({ ...filters, [e.target.name]: [...filters[e.target.name], e.target.value] });
    }
  };

  const handleDeleteFilter = (sectionTitle: string, elementTitle: string) => {
    let section = filters[sectionTitle];
    const index = section.indexOf(elementTitle);
    section.splice(index, 1);
    setFilters({ ...filters, [sectionTitle]: [...section] });
  };

  const { area, author, content } = filters;

  return (
    <div className='section__light'>
      <Fade in={!!searchTag} timeout={500}>
        <Container maxWidth='lg'>
          <div className='section_title-wrapper'>
            <h3 className='section_title'>
              Results for {searchTag}:{' '}
              {
                _.filter(
                  results,
                  (o: any) =>
                    (area.length > 0 ? (o.tags ? o.tags.some((el: string) => area.indexOf(el) >= 0) : false) : true) &&
                    (author.length > 0 ? author.includes(o.author) : true) &&
                    (content.length > 0 ? content.find((el: any) => o[el]) : true)
                ).length
              }
            </h3>
          </div>
          <div style={{ display: 'flex' }}>
            {!deviceXs && (
              <div>
                <div
                  style={{ display: 'flex', flexDirection: 'column', padding: deviceMd ? '0 24px 0 0' : '0 6rem 0 0' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1.5rem' }}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend' className='filter_section-title'>
                        Area
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={area.includes('Covid-19')}
                              onChange={handleChecked}
                              name='area'
                              value='Covid-19'
                            />
                          }
                          label='Covid-19'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={area.includes('Cardiology')}
                              onChange={handleChecked}
                              name='area'
                              value='Cardiology'
                            />
                          }
                          label='Cardiology'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={area.includes('Radiology')}
                              onChange={handleChecked}
                              name='area'
                              value='Radiology'
                            />
                          }
                          label='Radiology'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={area.includes('Therapy')}
                              onChange={handleChecked}
                              name='area'
                              value='Therapy'
                            />
                          }
                          label='Therapy'
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1.5rem' }}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend' className='filter_section-title'>
                        Author
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={author.includes('Bachanova')}
                              onChange={handleChecked}
                              name='author'
                              value='Bachanova'
                            />
                          }
                          label='Bachanova'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={author.includes('Jane Doe')}
                              onChange={handleChecked}
                              name='author'
                              value='Jane Doe'
                            />
                          }
                          label='Jane Doe'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={author.includes('John Doe')}
                              onChange={handleChecked}
                              name='author'
                              value='John Doe'
                            />
                          }
                          label='John Doe'
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend' className='filter_section-title'>
                        Content
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={content.includes('video')}
                              onChange={handleChecked}
                              name='content'
                              value='video'
                            />
                          }
                          label='Video'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={content.includes('audio')}
                              onChange={handleChecked}
                              name='content'
                              value='audio'
                            />
                          }
                          label='Audio'
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            )}
            <div>
              <div
                style={{
                  borderLeft: !deviceXs ? '1px solid rgba(225, 228, 233, 0.7)' : 0,
                  padding: deviceXs ? 0 : deviceMd ? '0 0 0 24px' : '0 0 0 6rem',
                }}
              >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {area.length > 0 && (
                    <>
                      <span className='filter_section-label'>Area: </span>
                      {area.map((el: string, key: number) => (
                        <span key={key} className='filter_option-label'>
                          {el}{' '}
                          <CloseIcon className='icon-button__default' onClick={() => handleDeleteFilter('area', el)} />
                        </span>
                      ))}
                    </>
                  )}
                  {author.length > 0 && (
                    <>
                      <span className='filter_section-label'>Author: </span>
                      {author.map((el: string, key: number) => (
                        <span key={key} className='filter_option-label'>
                          {el}{' '}
                          <CloseIcon
                            className='icon-button__default'
                            onClick={() => handleDeleteFilter('author', el)}
                          />
                        </span>
                      ))}
                    </>
                  )}
                  {content.length > 0 && (
                    <>
                      <span className='filter_section-label'>Content: </span>
                      {content.map((el: string, key: number) => (
                        <span key={key} className='filter_option-label'>
                          {el[0].toUpperCase().concat(el.slice(1))}{' '}
                          <CloseIcon
                            className='icon-button__default'
                            onClick={() => handleDeleteFilter('content', el)}
                          />
                        </span>
                      ))}
                    </>
                  )}
                </div>
                {_.shuffle(
                  _.filter(
                    results,
                    (o: any) =>
                      (area.length > 0
                        ? o.tags
                          ? o.tags.some((el: string) => area.indexOf(el) >= 0)
                          : false
                        : true) &&
                      (author.length > 0 ? author.includes(o.author) : true) &&
                      (content.length > 0 ? content.find((el: any) => o[el]) : true)
                  )
                ).map((article: IArticle | any, key) => (
                  <Fragment key={key}>
                    {key !== 0 && (
                      <Divider style={{ margin: deviceXs ? '1rem 0' : deviceSm ? '1.5rem 0' : '2rem 0' }} />
                    )}
                    <ArticleCard article={article} displayGrid={true} />
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </Fade>
    </div>
  );
};

export default Search;
