import { Container, Grid } from '@material-ui/core';
import React from 'react';
import Experts from './Experts';
import InterviewsColumn from './InterviewsColumn';
import Reports from './Reports';

interface Props {}

const Columns = (props: Props) => {
  return (
    <div className='section__dark'>
      <Container maxWidth='lg'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <Experts />
          </Grid>
          <Grid item xs={12} md={4}>
            <Reports />
          </Grid>
          <Grid item xs={12} md={3}>
            <InterviewsColumn />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Columns;
