import React, { useEffect, useState, MouseEvent } from 'react';
import { Chip, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';
import { getArticle, getTeaser } from '../../context/actions/article';

interface Props {
  match: any;
}

const Article = ({ match }: Props) => {
  const [content, setContent] = useState<any>(null);
  const [teaser, setTeaser] = useState<any>(null);

  useEffect(() => {
    const id = match?.params?.id;
    getTeaser(id).then((res: any) => setTeaser(res?.data?.RESULTOBJECT));
    getArticle(id).then((res: any) => {
      console.log(res.data);
      setContent(res?.data);
    });
  }, []);

  const convertDate = (unixTimestamp: string | number) => {
    let date =
      typeof unixTimestamp === 'string' ? new Date(parseInt(unixTimestamp, 10) * 1000) : new Date(unixTimestamp * 1000);
    let dd: number | string = date.getDate();
    let mm: number | string = date.getMonth() + 1;

    return `${
      mm === 1
        ? 'Jan'
        : mm === 2
        ? 'Feb'
        : mm === 3
        ? 'Mar'
        : mm === 4
        ? 'Apr'
        : mm === 5
        ? 'May'
        : mm === 6
        ? 'Jun'
        : mm === 7
        ? 'Jul'
        : mm === 8
        ? 'Aug'
        : mm === 9
        ? 'Sep'
        : mm === 10
        ? 'Oct'
        : mm === 11
        ? 'Nov'
        : mm === 12
        ? 'Dec'
        : null
    } ${dd}`;
  };

  const addBookmark = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Added to bookmarks');
  };

  return (
    <div>
      <Container maxWidth='md'>
        <div className='breadcrumbs-wrapper'>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Home
          </Link>
          <Link to='/hub' className='breadcrumb-button'>
            <ChevronLeftIcon /> Covid-19
          </Link>
        </div>

        <h2 className='article_title'>{teaser?.NAME}</h2>

        <div className='article_label-wrapper'>
          <span className='article_label'>
            Bachanova <span className='article_text-secondary'>in</span> Therapy guidlines
          </span>
        </div>
        <div className='article_info-wrapper'>
          <span className='article_text-secondary'>
            {convertDate(Math.floor(+new Date() / 1000))} &middot; 5 mins to read{' '}
            <span className='article_button' onClick={addBookmark}>
              <BookmarkIcon /> Add to bookmarks
            </span>
          </span>
        </div>

        <div style={{ margin: '0 0 1.5rem 0' }}>
          {['Covid-19', 'Cardiology', 'Radiology', 'Therapy'].map((tag: string, key: number) => (
            <Chip key={key} color='primary' label={tag} size='small' style={{ marginRight: '0.5rem' }} />
          ))}
        </div>

        <div className='article_link-wrapper'>
          <Link to='/' className='article_link'>
            Empfehlungen zur CAR-T-Zell-Therapie: Behandlungen sollten während der COVID-19-Pandemie fortgesetzt werden
          </Link>
        </div>

        {(teaser?.IMAGE_BASE64 || teaser?.IMAGE_SMALL_BASE64) && (
          <div
            style={{
              width: '100%',
              maxWidth: '100%',
              // maxHeight: '24rem',
              marginBottom: '2rem',
              overflow: 'hidden',
            }}
          >
            <img
              src={`data:image/png;base64, ${teaser?.IMAGE_BASE64 || teaser?.IMAGE_SMALL_BASE64}`}
              alt=''
              style={{
                width: '100%',
                // height: '24rem',
                objectFit: 'cover',
              }}
            />
          </div>
        )}

        <div className='content-wrapper' dangerouslySetInnerHTML={{ __html: `${content}` }} />
      </Container>
    </div>
  );
};

export default Article;
