import React, { Fragment } from 'react';
import { Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import HubCard from '../reusable/HubCard';

import colonoscopy from '../../media/placeholders/colonoscopy.png';
import doctor from '../../media/placeholders/doctor.png';
import protectiveWear from '../../media/placeholders/protective-wear.png';
import ArticleCard from '../reusable/ArticleCard';
import { IArticle } from '../types';

interface Props {}

const TopSection = (props: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const deviceMd = useMediaQuery(theme.breakpoints.down('md'));

  const articles = [
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
  ];

  return (
    <div className='section__light'>
      <Container maxWidth='lg'>
        <Grid container spacing={deviceSm ? 2 : 4}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={deviceSm ? 2 : 4}>
              <Grid item xs={12}>
                <HubCard large={true} hub={{ title: 'Cardiology', amount: 21, notif: true }} />
              </Grid>
              {!deviceSm && (
                <>
                  <Grid item xs={4}>
                    <HubCard
                      large={false}
                      hub={{ title: 'Diagnostic', amount: 34, notif: false, image: colonoscopy }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <HubCard
                      large={false}
                      hub={{
                        title: 'Diagnostic',
                        amount: 12,
                        notif: false,
                        video: true,
                        contentTitle: 'Chimeric antigen receptor',
                        contentSubtitle: 'Bachanova et al.',
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <HubCard
                      large={false}
                      hub={{
                        title: 'Medical information resources',
                        amount: 2,
                        notif: false,
                        audio: true,
                        contentTitle: 'Chimeric antigen receptor',
                        contentSubtitle: 'Bachanova et al.',
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            {articles.slice(0, deviceMd ? 2 : 3).map((article: IArticle, key) => (
              <Fragment key={key}>
                {(key !== 0 || deviceSm) && <Divider style={{ margin: '1rem 0' }} />}
                <ArticleCard article={article} displayGrid={true} />
              </Fragment>
            ))}
          </Grid>

          {deviceSm && (
            <Grid item xs={12}>
              <Grid container spacing={deviceSm ? 2 : 4}>
                {!deviceXs && (
                  <Grid item xs={6} sm={4}>
                    <HubCard large={false} hub={{ title: 'Diagnostic', amount: 4, notif: false }} />
                  </Grid>
                )}
                <Grid item xs={6} sm={4}>
                  <HubCard
                    large={false}
                    hub={{
                      title: 'Diagnostic',
                      amount: 12,
                      notif: false,
                      video: true,
                      contentTitle: 'Chimeric antigen receptor',
                      contentSubtitle: 'Bachanova et al.',
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <HubCard
                    large={false}
                    hub={{
                      title: 'Medical information resources',
                      amount: 2,
                      notif: false,
                      audio: true,
                      contentTitle: 'Chimeric antigen receptor',
                      contentSubtitle: 'Bachanova et al.',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default TopSection;
