import React, { Fragment } from 'react';
import ArticleCard from '../reusable/ArticleCard';
import { IArticle } from '../types';
import doctorGlasses from '../../media/placeholders/doctor-glasses.png';
import femaleDoctor from '../../media/placeholders/female-doctor.png';
import femaleDoctor1 from '../../media/placeholders/female-doctor1.png';
import { Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface Props {}

const Experts = (props: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const articles: IArticle[] = [
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      // text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: doctorGlasses,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      // text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: femaleDoctor,
      audio: true,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      // text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: femaleDoctor1,
    },
  ];

  return (
    <div>
      <div className='section_title-wrapper'>
        <h3 className='section_title'>Experts</h3>
        <span className='see-all-button action-button__primary' onClick={() => history.push('/experts/all')}>
          See All
        </span>
      </div>
      {articles.slice(0, deviceSm ? 2 : 3).map((article: IArticle, key) => (
        <Fragment key={key}>
          {key !== 0 && <Divider style={{ margin: '1rem 0' }} />}
          <ArticleCard article={article} displayGrid={true} />
        </Fragment>
      ))}
    </div>
  );
};

export default Experts;
