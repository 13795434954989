import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import HubCard from '../reusable/HubCard';
import { IHub } from '../types';

interface Props {}

const InterviewsColumn = (props: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const hubs: IHub[] = [
    {
      title: 'Diagnostic',
      contentTitle: 'Sustained Remission Achieved from Anti-CD19',
      contentSubtitle: 'Bachanov et al.',
      video: true,
    },
    {
      title: 'Diagnostic',
      contentTitle: 'Sustained Remission Achieved from Anti-CD19',
      contentSubtitle: 'Bachanov et al.',
      video: true,
    },
    {
      title: 'Diagnostic',
      contentTitle: 'Sustained Remission Achieved from Anti-CD19',
      contentSubtitle: 'Bachanov et al.',
      video: true,
    },
  ];

  return (
    <div>
      <div className='section_title-wrapper'>
        <h3 className='section_title'>Interviews</h3>
        <span className='see-all-button action-button__primary' onClick={() => history.push('interviews/all')}>See All</span>
      </div>
      <Grid container spacing={2}>
        {hubs.slice(0, deviceSm ? 2 : 3).map((hub: IHub, key) => (
          <Grid item xs={6} md={12}><HubCard hub={hub} large={false} /></Grid>
        ))}
      </Grid>
    </div>
  );
};

export default InterviewsColumn;
