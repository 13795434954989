import React, { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { Button, Checkbox, Container, FormControlLabel, TextField } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { ReactComponent as CheckedIcon } from '../../media/icons/checked.svg';
import { ReactComponent as CheckboxBlankIcon } from '../../media/icons/checkbox-blank.svg';
import { ReactComponent as FacebookIcon } from '../../media/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../media/icons/twitter.svg';
import { ReactComponent as PrintIcon } from '../../media/icons/print.svg';
import { ReactComponent as MailIcon } from '../../media/icons/mail.svg';
import { AuthContext } from '../../context/AuthContext';
import { AxiosError, AxiosResponse } from 'axios';

interface Props {}

const SignIn = (props: Props) => {
  const { login } = useContext(AuthContext);

  const history = useHistory();

  const [email, setEmail] = useState<string>('medwizuser');
  const [password, setPassword] = useState<string>('htUqEyA2mzQgQBpz');

  const [agreed, setAgreed] = useState<boolean>(false);

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const response = await fetch('https://demo.med-i-cloud.ws/medic/1/um/login', {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'text/plain',
    //   },
    //   body: JSON.stringify({
    //     partnerid: 'P6sqagav4gDxBXEY',
    //     moduleparameters: { username: 'medwizuser', password: 'htUqEyA2mzQgQBpz' },
    //   }),
    // });
    // console.log(response);
    return login({ email, password })
      .then((res: AxiosResponse) => res.status === 200 && history.push('/'))
      .catch((err: AxiosError) => console.log(err));
  };

  return (
    <div className='auth-wrapper'>
      <Container maxWidth='md'>
        <div className='page-title-wrapper'>
          <h2 className='page-title'>Anmeldung</h2>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Home page
          </Link>
        </div>
        <div className='section__light'>
          <p className='auth_text-main'>
            Angemeldete Nutzer haben Zugriff auf zusätzliche Infos und Services von MMI sowie auf den geschlossenen
            Bereich unserer Webseite Gelbe Liste Online.
          </p>
          <p className='auth_text-bold'>Hinweis: Anmeldung mit Gelbe Liste Online Zugangsdaten möglich</p>
          <p className='auth_text-main'>
            Wenn Sie bereits ein Nutzerkonto für unser Portal{' '}
            <a className='auth_link' href='#'>
              Gelbe Liste Online haben
            </a>
            , können Sie sich mit diesen Zugangsdaten direkt{' '}
            <a className='auth_link' href='#'>
              hier einloggen
            </a>
            . Nach einer Registrierung können Sie sich mit Ihren Zugangsdaten dann auch jederzeit auf unserer Webseite{' '}
            <a className='auth_link' href='#'>
              Gelbe Liste Online
            </a>{' '}
            anmelden.
          </p>

          <Container maxWidth='xs' disableGutters>
            <form className='auth_form' onSubmit={handleLogin}>
              <TextField
                fullWidth
                // label='E-Mail-Adresse'
                // type='email'
                label='Nutzername'
                type='text'
                style={{ marginBottom: '2rem' }}
                variant='outlined'
                size='small'
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
              <TextField
                fullWidth
                label='Passwort'
                type='password'
                variant='outlined'
                size='small'
                value={password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              />
              <span className='auth_helper-button'>Passwort vergessen?</span>
              <div className='auth_checkbox-wrapper'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreed}
                      onChange={() => setAgreed(!agreed)}
                      icon={<CheckboxBlankIcon />}
                      checkedIcon={<CheckedIcon />}
                    />
                  }
                  label={<span className='auth_checkbox-label'>Anmeldung merken</span>}
                />
              </div>
              <Button
                color='primary'
                variant='contained'
                type='submit'
                size='large'
                style={{ marginTop: '3.5rem' }}
                disabled={!email || !password || !agreed}
              >
                Anmelden
              </Button>
            </form>
          </Container>
        </div>
      </Container>
      <Container maxWidth='md'>
        <div className='auth_social-wrapper'>
          <FacebookIcon className='auth_social-button' />
          <TwitterIcon className='auth_social-button' />
          <PrintIcon className='auth_social-button' />
          <MailIcon className='auth_social-button' />
        </div>

        <p className='auth_text-secondary'>
          Der Schutz Ihrer persönlichen Daten ist uns wichtig. Sie haben jederzeit das Recht, Ihre Daten bei uns
          einzusehen, sperren oder löschen zu lassen und Ihre Zustimmung zu unserer Datenschutzerkärung zu widerrufen.
          Ihre Daten werden dazu verwendet, redaktionelle Inhalte, Produktinformationen und Services den hierfür
          berechtigten Berufsgruppen gemäß Heilmittelwerbegesetz bereitzustellen. Für Details zu Zweck und Verwendung
          Ihrer Daten beachten Sie bitte unsere{' '}
          <a className='auth_link' href='#'>
            Datenschutzerklärung
          </a>{' '}
          und wenden Sie sich bei Fragen zum Datenschutz an{' '}
          <a className='auth_link' href={`mailto:datenschutz@mmi.de`}>
            datenschutz@mmi.de
          </a>
          .
        </p>
      </Container>
    </div>
  );
};

export default SignIn;
