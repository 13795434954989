import React, { useState, useRef, useContext } from 'react';
import {
  AppBar,
  Badge,
  ClickAwayListener,
  Container,
  Fab,
  Grow,
  Paper,
  Popper,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
  Zoom,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import logo from '../media/logo.svg';
import { ReactComponent as BookmarkIcon } from '../media/icons/bookmark.svg';
import { ReactComponent as BellIcon } from '../media/icons/bell.svg';
import { ReactComponent as AvatarIcon } from '../media/icons/user.svg';
import { ReactComponent as BurgerIcon } from '../media/icons/burger.svg';
import { ReactComponent as ChevronDownIcon } from '../media/icons/chevron-down.svg';
import SearchInput from './reusable/SearchInput';
import { AuthContext } from '../context/AuthContext';

interface Props {
  children: any;
}

const Nav = ({ children }: Props) => {
  const { isLogged } = useContext(AuthContext);
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchTag, setSearchTag] = useState<string>('');
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false);
  const [authPage, setAuthPage] = useState<boolean>(false);
  const [profilePage, setProfilePage] = useState<boolean>(false);

  const anchorRef = useRef(null);

  const user: any = {
    name: 'Dr. Anna',
  };

  const showBackToTop = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleBackToTop = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div>
      <AppBar position='static' color='transparent'>
        <Container maxWidth='lg'>
          <Toolbar id='back-to-top-anchor' style={{ minHeight: deviceXs ? '2rem' : '4rem', padding: 0 }}>
            <Link to='/'>
              <img
                src={logo}
                alt=''
                style={{ maxHeight: deviceXs ? '1rem' : '2rem', transition: 'all 0.3s ease 0s' }}
              />
            </Link>
            {!isLogged ? (
              <>
                {window.location.href.includes('/auth/signup') ? (
                  <Link to='/auth/signin' className='nav_link'>
                    Anmeldung
                  </Link>
                ) : window.location.href.includes('/auth/signin') ? (
                  <Link to='/auth/signup' className='nav_link'>
                    Registrierung
                  </Link>
                ) : (
                  <>
                    {deviceXs ? (
                      <>
                        <BurgerIcon
                          ref={anchorRef}
                          className='icon-button__dark'
                          onClick={() => setOpenProfileMenu(!openProfileMenu)}
                        />
                        <Popper
                          open={openProfileMenu}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          transition
                          disablePortal
                          style={{ zIndex: 1 }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                              }}
                            >
                              <Paper elevation={1}>
                                <ClickAwayListener onClickAway={() => setOpenProfileMenu(false)}>
                                  <div
                                    style={{
                                      padding: '0.75rem',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Link
                                      to='/profile'
                                      className='action-button__default'
                                      onClick={() => setOpenProfileMenu(false)}
                                      style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                    >
                                      Profil
                                    </Link>
                                    <Link
                                      to='/profile/change-password'
                                      className='action-button__default'
                                      onClick={() => setOpenProfileMenu(false)}
                                      style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                    >
                                      Passwort ändern
                                    </Link>
                                    <span
                                      className='action-button__default'
                                      onClick={() => setOpenProfileMenu(false)}
                                      style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                    >
                                      Notifications
                                    </span>
                                    <span
                                      className='action-button__default'
                                      onClick={() => setOpenProfileMenu(false)}
                                      style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                    >
                                      Bookmarks
                                    </span>
                                  </div>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </>
                    ) : (
                      <>
                        <div
                          ref={anchorRef}
                          onClick={() => setOpenProfileMenu(!openProfileMenu)}
                          className='action-button__default'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <AvatarIcon />
                          <span style={{ margin: '0 .5rem' }}>{user.name}</span>
                          <ChevronDownIcon
                            style={{
                              transition: 'all 0.3s ease 0s',
                              transform: openProfileMenu ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                          />
                        </div>
                        <Popper
                          open={openProfileMenu}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          transition
                          disablePortal
                          style={{ zIndex: 1 }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                              <Paper elevation={1}>
                                <ClickAwayListener onClickAway={() => setOpenProfileMenu(false)}>
                                  <div style={{ padding: '0.75rem', display: 'flex', flexDirection: 'column' }}>
                                    <Link
                                      to='/profile'
                                      className='action-button__default'
                                      onClick={() => setOpenProfileMenu(false)}
                                      style={{ marginBottom: '1rem', lineHeight: '1rem' }}
                                    >
                                      Profil
                                    </Link>
                                    <Link
                                      to='/profile/change-password'
                                      className='action-button__default'
                                      onClick={() => setOpenProfileMenu(false)}
                                      style={{ lineHeight: '1rem' }}
                                    >
                                      Passwort ändern
                                    </Link>
                                  </div>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {!deviceXs ? (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SearchInput searchTag={searchTag} setSearchTag={setSearchTag} />
                      <BookmarkIcon className='icon-button__dark' style={{ margin: '0 .5rem 0 1rem' }} />
                      <div style={{ margin: '0 .5rem' }}>
                        <Badge color='primary' overlap='circle' variant='dot'>
                          <BellIcon className='icon-button__dark' />
                        </Badge>
                      </div>
                    </div>

                    <div
                      ref={anchorRef}
                      onClick={() => setOpenProfileMenu(!openProfileMenu)}
                      className='action-button__default'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <AvatarIcon />
                      <span style={{ margin: '0 .5rem' }}>{user.name}</span>
                      <ChevronDownIcon
                        style={{
                          transition: 'all 0.3s ease 0s',
                          transform: openProfileMenu ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                      />
                    </div>
                    <Popper
                      open={openProfileMenu}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      style={{ zIndex: 1 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper elevation={1}>
                            <ClickAwayListener onClickAway={() => setOpenProfileMenu(false)}>
                              <div style={{ padding: '0.75rem', display: 'flex', flexDirection: 'column' }}>
                                <Link
                                  to='/profile'
                                  className='action-button__default'
                                  onClick={() => setOpenProfileMenu(false)}
                                  style={{ marginBottom: '1rem', lineHeight: '1rem' }}
                                >
                                  Profil
                                </Link>
                                <Link
                                  to='/profile/change-password'
                                  className='action-button__default'
                                  onClick={() => setOpenProfileMenu(false)}
                                  style={{ lineHeight: '1rem' }}
                                >
                                  Passwort ändern
                                </Link>
                              </div>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                ) : (
                  <>
                    <BurgerIcon
                      ref={anchorRef}
                      className='icon-button__dark'
                      onClick={() => setOpenProfileMenu(!openProfileMenu)}
                    />
                    <Popper
                      open={openProfileMenu}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      style={{ zIndex: 1 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper elevation={1}>
                            <ClickAwayListener onClickAway={() => setOpenProfileMenu(false)}>
                              <div
                                style={{
                                  padding: '0.75rem',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <Link
                                  to='/profile'
                                  className='action-button__default'
                                  onClick={() => setOpenProfileMenu(false)}
                                  style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                >
                                  Profil
                                </Link>
                                <Link
                                  to='/profile/change-password'
                                  className='action-button__default'
                                  onClick={() => setOpenProfileMenu(false)}
                                  style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                >
                                  Passwort ändern
                                </Link>
                                <span
                                  className='action-button__default'
                                  onClick={() => setOpenProfileMenu(false)}
                                  style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                >
                                  Notifications
                                </span>
                                <span
                                  className='action-button__default'
                                  onClick={() => setOpenProfileMenu(false)}
                                  style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                >
                                  Bookmarks
                                </span>
                              </div>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                )}
              </>
            )}
          </Toolbar>
          {deviceXs && !window.location.href.includes('/auth') && !window.location.href.includes('/profile') && (
            <SearchInput searchTag={searchTag} setSearchTag={setSearchTag} />
          )}
        </Container>
      </AppBar>
      <div style={{ height: '100%', minHeight: `calc(100vh - ${deviceXs ? '7.5rem' : '9rem'})` }}>{children}</div>
      <Zoom in={showBackToTop}>
        <div onClick={handleBackToTop} role='presentation' style={{ position: 'fixed', bottom: '2rem', right: '2rem' }}>
          <Fab color='default' size='small' aria-label='scroll back to top' style={{ opacity: 0.7 }}>
            <ChevronDownIcon fill='#2C364F' style={{ transform: 'rotate(180deg)', width: '1rem', height: '1rem' }} />
          </Fab>
        </div>
      </Zoom>
      <AppBar position='static' color='default' className='footer'>
        <Container maxWidth='lg'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: deviceXs ? '3rem' : '5rem',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <a href='#' className='nav_link'>
                Impressum
              </a>
              <span style={{ margin: '0 .5rem' }}>|</span>
              <a href='#' className='nav_link'>
                Datenschutz
              </a>
              <span style={{ margin: '0 .5rem' }}>|</span>
              <a href='#' className='nav_link'>
                Nutzungsbedingungen
              </a>
              <span style={{ margin: '0 .5rem' }}>|</span>
              <a href='#' className='nav_link'>
                AGB
              </a>
            </div>
            <span>
              <b>© Medizinische Medien Informations GmbH</b>
            </span>
          </div>
        </Container>
      </AppBar>
    </div>
  );
};

export default Nav;
