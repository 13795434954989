import React from 'react';
import { Chip, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { IArticle } from '../types';
import pen from '../../media/placeholders/pen.png';
import protectiveWear from '../../media/placeholders/protective-wear-large.png';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';

interface Props {}

const Article = (props: Props) => {
  const article: IArticle | any = {
    author: 'Bachanova',
    area: 'Theraphy guidlines',
    tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
    title: 'Chimeric Antigen Receptor T Cell Therapy During the COVID-19 Pandemic',
    text: `Das CAR T Cell-Konsortium, ein Verbund von Forschern ausacht US-amerikanischen wissenschaftlichen Institutionen veröffentlichte in einem am 14. April 2020 in der Zeitschrift Biology of Blood and Marrow Transplantation publizierten Artikel Konsensusempfehlungen zur CAR-T-Zelltherapie während der COVID-19-Pandemie. Sie richten sich an Behandlungszentren und sollen dazu beitragen, das Überleben und die Lebensqualität der Patienten zu verbessern.
      
Die Autoren betonen, dass die Verabreichung einer CAR-T-Zelltherapie während einer Pandemie eine ausführliche Diskussion der Risiken und des Nutzens sowie eine sorgfältige Bewertung, Planung und Nutzung der Ressourcen erfordert. Trotz dieser Herausforderungen sollte bei Patienten mit B-Zell-Non-Hodgkin-Lymphomen und B-Zell-akuter lymphatischer Leukämie die Behandlung mit einer CAR-T-Zelltherapie fortgesetzt werden [1].`,
    date: Math.floor(+new Date() / 1000),
    estimate: 7,
    image: pen,
    list: [
      `Richten Sie einen Triage-Algorithmus ein, um so viele CAR-T-Aktivitäten wie möglich sicher zu verzögern oder abzubrechen.`,
      `Stellen Sie sicher, dass speziell dem Empfang, der Bearbeitung und der Infusion von Produkten zugeordnetes Personal zur Verfügung steht.`,
      `Priorisieren Sie Produkte, die ambulant verabreicht werden können, und sorgen Sie für eine angemessene Nachsorge für Patienten, bei denen Toxizitäten auftreten.`,
      `Starten Sie die Chemotherapie zur Lymphodepletion erst nach Erhalt des Zellprodukts.`,
      `Regen Sie virtuelle Teambesprechungen an und führen Sie gegebenenfalls nur eine Untersuchung pro Patient und Tag durch. Erwägen Sie, auf Untersuchungen zu verzichten, die die Bewertung oder das Management wahrscheinlich nicht beeinflussen.`,
      `Stellen Sie sicher, dass es für die vier Wochen rund um den Eingriff einen Belegungsplan für die Patienten gibt`,
    ],
  };

  const convertDate = (unixTimestamp: string | number) => {
    let date =
      typeof unixTimestamp === 'string' ? new Date(parseInt(unixTimestamp, 10) * 1000) : new Date(unixTimestamp * 1000);
    let dd: number | string = date.getDate();
    let mm: number | string = date.getMonth() + 1;

    return `${
      mm === 1
        ? 'Jan'
        : mm === 2
        ? 'Feb'
        : mm === 3
        ? 'Mar'
        : mm === 4
        ? 'Apr'
        : mm === 5
        ? 'May'
        : mm === 6
        ? 'Jun'
        : mm === 7
        ? 'Jul'
        : mm === 8
        ? 'Aug'
        : mm === 9
        ? 'Sep'
        : mm === 10
        ? 'Oct'
        : mm === 11
        ? 'Nov'
        : mm === 12
        ? 'Dec'
        : null
    } ${dd}`;
  };

  return (
    <div>
      <Container maxWidth='md'>
        <div className='breadcrumbs-wrapper'>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Home
          </Link>
          <Link to='/hub' className='breadcrumb-button'>
            <ChevronLeftIcon /> Covid-19
          </Link>
        </div>

        <h2 className='article_title'>{article.title}</h2>

        <div className='article_label-wrapper'>
          <span className='article_label'>
            {article.author} <span className='article_text-secondary'>in</span> {article.area}
          </span>
        </div>
        <div className='article_info-wrapper'>
          <span className='article_text-secondary'>
            {convertDate(article.date)} &middot; {article.estimate} mins to read &middot;{' '}
            <span className='article_button'>
              <BookmarkIcon /> Add to bookmarks
            </span>
          </span>
        </div>

        <div style={{ margin: '0 0 1.5rem 0' }}>
          {article.tags.map((tag: string, key: number) => (
            <Chip key={key} color='primary' label={tag} size='small' style={{ marginRight: '0.5rem' }} />
          ))}
        </div>

        <div className='article_link-wrapper'>
          <Link to='/' className='article_link'>
            Empfehlungen zur CAR-T-Zell-Therapie: Behandlungen sollten während der COVID-19-Pandemie fortgesetzt werden
          </Link>
        </div>

        <h6 className='article_subtitle'>Zusammenfassung</h6>

        {article.text && <pre className='article_text'>{article.text}</pre>}

        {article.image && (
          <div style={{ width: '100%', maxWidth: '100%' }}>
            <img src={article.image} alt='' style={{ width: '100%', maxWidth: '100%', maxHeight: '18rem' }} />
          </div>
        )}

        <h6 className='article_subtitle'>Inhalte der Leitlinie</h6>

        <pre className='article_text'>
          {`Das CAR T Cell-Konsortium formulierte die Empfehlungen als Antworten auf sieben Schlüsselfragen. Im Folgenden geben wir die Kurzversion größtenteils unverändert wieder [2].`}
        </pre>

        <div className='article_link-wrapper'>
          <Link to='/' className='article_link'>
            Welche Ressourcen sind für die sichere Verabreichung der CAR-T-Zelltherapie während der COVID-19-Pandemie
            erforderlich?
          </Link>
        </div>

        <pre className='article_text'>
          {`Es ist wichtig, Ressourcenprobleme zu antizipieren, da die CAR-T-Zelltherapie nach Beginn nicht abgebrochen werden kann. Behandlungszentren können aufgrund ihres Standorts und lokaler Probleme in der Lieferkette zu unterschiedlichen Zeiten betroffen sein.

Zu den praktischen Überlegungen zur sicheren Behandlung von Patienten angesichts möglicher Störungen gehören:`}
        </pre>

        <ul className='article_list'>
          {article.list.map((el: string, key: number) => (
            <li key={key} className='article_list-item'>
              {el}
            </li>
          ))}
        </ul>

        <div style={{ width: '100%', maxWidth: '100%' }}>
          <img src={protectiveWear} alt='' style={{ width: '100%', maxWidth: '100%', maxHeight: '18rem' }} />
        </div>
      </Container>
    </div>
  );
};

export default Article;
