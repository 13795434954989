import React, { useState, useRef, MouseEvent, useEffect, ChangeEvent } from 'react';
import { Avatar, Chip, Container } from '@material-ui/core';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';
import { IArticle } from '../types';
import { Link } from 'react-router-dom';
import talkingHeads from '../../media/placeholders/talking-heads_burning-down-the-house.mp3';
import AudioPlayer from './AudioPlayer';
import doctor1 from '../../media/placeholders/doctor-portrait.png';
import VideoPlayer from './VideoPlayer';

interface Props {
  video: boolean;
}

const Media = ({ video }: Props) => {
  const article: IArticle | any = {
    author: 'Bachanova',
    area: 'Covid-19',
    tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
    title: video
      ? 'Covid-19 and Oncology'
      : 'CImmuncheckpoint-Inhibitoren (Atezolizumab,Cemiplimab,Durvalumab): Änderung Produktinformation',
    date: Math.floor(+new Date() / 1000),
    avatar: doctor1,
  };

  const convertDate = (unixTimestamp: string | number) => {
    let date =
      typeof unixTimestamp === 'string' ? new Date(parseInt(unixTimestamp, 10) * 1000) : new Date(unixTimestamp * 1000);
    let dd: number | string = date.getDate();
    let mm: number | string = date.getMonth() + 1;

    return `${
      mm === 1
        ? 'Jan'
        : mm === 2
        ? 'Feb'
        : mm === 3
        ? 'Mar'
        : mm === 4
        ? 'Apr'
        : mm === 5
        ? 'May'
        : mm === 6
        ? 'Jun'
        : mm === 7
        ? 'Jul'
        : mm === 8
        ? 'Aug'
        : mm === 9
        ? 'Sep'
        : mm === 10
        ? 'Oct'
        : mm === 11
        ? 'Nov'
        : mm === 12
        ? 'Dec'
        : null
    } ${dd}`;
  };

  return (
    <div>
      <Container maxWidth='md'>
        <div className='breadcrumbs-wrapper'>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Home
          </Link>
          <Link to='/hub' className='breadcrumb-button'>
            <ChevronLeftIcon /> Covid-19
          </Link>
        </div>

        <h2 className='article_title'>{article.title}</h2>

        <div className='article_label-wrapper'>
          <span className='article_label'>
            {article.author} <span className='article_text-secondary'>in</span> {article.area}
          </span>
        </div>
        <div className='article_info-wrapper'>
          <span className='article_text-secondary'>
            {convertDate(article.date)} &middot;{' '}
            <span className='article_button'>
              <BookmarkIcon /> Add to bookmarks
            </span>
          </span>
        </div>

        <div style={{ margin: '0 0 1.5rem 0' }}>
          {article.tags.map((tag: string, key: number) => (
            <Chip key={key} color='primary' label={tag} size='small' style={{ marginRight: '0.5rem' }} />
          ))}
        </div>
        {video ? (
          <VideoPlayer />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {article.avatar && (
              <Avatar src={article.avatar} style={{ width: '4rem', height: '4rem', marginRight: '0.5rem' }} />
            )}
            <AudioPlayer src={talkingHeads} />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Media;
