import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { ReactComponent as ChevronDownIcon } from '../../media/icons/chevron-down-filled.svg';
import { ReactComponent as CheckedIcon } from '../../media/icons/checked.svg';
import { ReactComponent as CheckboxBlankIcon } from '../../media/icons/checkbox-blank.svg';
import { ReactComponent as FacebookIcon } from '../../media/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../media/icons/twitter.svg';
import { ReactComponent as PrintIcon } from '../../media/icons/print.svg';
import { ReactComponent as MailIcon } from '../../media/icons/mail.svg';

interface Props {}

const ChangePassword = (props: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  const history = useHistory();

  const [agreed, setAgreed] = useState<boolean>(false);

  return (
    <div className='auth-wrapper'>
      <Container maxWidth='md'>
        <div className='page-title-wrapper'>
          <h2 className='page-title'>Passwort ändern</h2>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Home page
          </Link>
        </div>
        <div className='section__light'>
          <Container maxWidth='xs' disableGutters>
            <form className='auth_form' onSubmit={() => history.push('profile')}>
              <TextField
                fullWidth
                label='Altes Passwort'
                type='password'
                style={{ marginBottom: '2rem' }}
                variant='outlined'
                size='small'
              />
              <TextField fullWidth label='Neues Passwort' type='password' variant='outlined' size='small' />
              <span className='auth_helper-text'>min. 6 Zeichen, davon min. 1 Zahl</span>
              <TextField
                fullWidth
                label='Neues Passwort wiederholen'
                type='password'
                style={{ marginTop: '0.75rem' }}
                variant='outlined'
                size='small'
              />
              <div className='auth_checkbox-wrapper'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreed}
                      onChange={() => setAgreed(!agreed)}
                      icon={<CheckboxBlankIcon />}
                      checkedIcon={<CheckedIcon />}
                    />
                  }
                  label={<span className='auth_checkbox-label'>Ändere Passwort</span>}
                />
              </div>
              <Button color='primary' variant='contained' type='submit' size='large' style={{ marginTop: '3.5rem' }}>
                Anmelden
              </Button>
            </form>
          </Container>
        </div>
      </Container>
      <Container maxWidth='md'>
        <div className='auth_social-wrapper'>
          <FacebookIcon className='auth_social-button' />
          <TwitterIcon className='auth_social-button' />
          <PrintIcon className='auth_social-button' />
          <MailIcon className='auth_social-button' />
        </div>

        <p className='auth_text-secondary'>
          Der Schutz Ihrer persönlichen Daten ist uns wichtig. Sie haben jederzeit das Recht, Ihre Daten bei uns
          einzusehen, sperren oder löschen zu lassen und Ihre Zustimmung zu unserer Datenschutzerkärung zu widerrufen.
          Ihre Daten werden dazu verwendet, redaktionelle Inhalte, Produktinformationen und Services den hierfür
          berechtigten Berufsgruppen gemäß Heilmittelwerbegesetz bereitzustellen. Für Details zu Zweck und Verwendung
          Ihrer Daten beachten Sie bitte unsere{' '}
          <a className='auth_link' href='#'>
            Datenschutzerklärung
          </a>{' '}
          und wenden Sie sich bei Fragen zum Datenschutz an{' '}
          <a className='auth_link' href={`mailto:datenschutz@mmi.de`}>
            datenschutz@mmi.de
          </a>
          .
        </p>
      </Container>
    </div>
  );
};

export default ChangePassword;
