import React, { MouseEvent } from 'react';
import { Avatar, Chip, Grid } from '@material-ui/core';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';
import { useHistory } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';
import HubCard from './HubCard';

interface Props {
  article: any;
  displayGrid?: boolean;
}

const Teaser = ({ article, displayGrid }: Props) => {
  const history = useHistory();

  const convertDate = (unixTimestamp: string | number) => {
    let date =
      typeof unixTimestamp === 'string' ? new Date(parseInt(unixTimestamp, 10) * 1000) : new Date(unixTimestamp * 1000);
    let dd: number | string = date.getDate();
    let mm: number | string = date.getMonth() + 1;

    return `${
      mm === 1
        ? 'Jan'
        : mm === 2
        ? 'Feb'
        : mm === 3
        ? 'Mar'
        : mm === 4
        ? 'Apr'
        : mm === 5
        ? 'May'
        : mm === 6
        ? 'Jun'
        : mm === 7
        ? 'Jul'
        : mm === 8
        ? 'Aug'
        : mm === 9
        ? 'Sep'
        : mm === 10
        ? 'Oct'
        : mm === 11
        ? 'Nov'
        : mm === 12
        ? 'Dec'
        : null
    } ${dd}`;
  };

  const addBookmark = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Added to bookmarks');
  };

  const handleAudio = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div onClick={() => history.push(article?.audio ? '/audio/id' : `/articles/${article?.DOCUMENTID}`)}>
      {!displayGrid && (
        <>
          {(article?.AUTHOR || true) && (
            <div className='article-card_label-wrapper'>
              <span className='article_label'>
                {article?.AUTHOR || 'Bachanova '}
                <span className='article-card_text-secondary'>in</span> {article?.area || 'Therapy guidlines'}
              </span>
            </div>
          )}
          <div style={{ margin: '0.5rem 0' }}>
            {['Covid-19', 'Cardiology', 'Radiology', 'Therapy'].map((tag: string, key: number) => (
              <Chip key={key} color='primary' label={tag} size='small' style={{ marginRight: '0.5rem' }} />
            ))}
          </div>
        </>
      )}
      <div className='article-card-wrapper'>
        {article?.avatar && (
          <Avatar src={article?.avatar} style={{ width: '4rem', height: '4rem', marginRight: '0.5rem' }} />
        )}
        <div style={{ maxWidth: '100%' }}>
          {displayGrid && (article?.AUTHOR || true) && (
            <div className='article-card_label-wrapper'>
              <span className='article-card_label'>
                {article?.AUTHOR || 'Bachanova '}
                <span className='article-card_text-secondary'>in</span> {article?.area || 'Therapy guidlines'}
              </span>
            </div>
          )}

          <div>
            <Grid container spacing={1}>
              <Grid
                item
                xs={displayGrid && (article?.IMAGE_SMALL_BASE64 || article?.IMAGE_BASE64 || article?.video) ? 8 : 12}
                lg={displayGrid && (article?.IMAGE_SMALL_BASE64 || article?.IMAGE_BASE64 || article?.video) ? 9 : 12}
              >
                {displayGrid && (
                  <div style={{ margin: '0.5rem 0' }}>
                    {['Covid-19', 'Cardiology', 'Radiology', 'Therapy'].map((tag: string, key: number) => (
                      <Chip key={key} color='primary' label={tag} size='small' style={{ marginRight: '0.5rem' }} />
                    ))}
                  </div>
                )}
                {article?.logo && (
                  <div style={{ marginBottom: '0.5rem' }}>
                    <img src={article?.logo} alt='' />
                  </div>
                )}
                <div className='article-card_title-wrapper'>
                  <h4 className='article-card_title'>{article?.NAME}</h4>
                </div>
              </Grid>

              {displayGrid && (article?.IMAGE_SMALL_BASE64 || article?.IMAGE_BASE64 || article?.video) && (
                <Grid item xs={4} lg={3}>
                  {article?.video ? (
                    <HubCard
                      hub={{
                        title: 'Diagnostic',
                        contentTitle: 'Sustained Remission Achieved from Anti-CD19',
                        contentSubtitle: 'Bachanov et al.',
                        video: true,
                      }}
                      large={false}
                    />
                  ) : (
                    <img
                      src={`data:image/png;base64, ${article?.IMAGE_SMALL_BASE64 || article?.IMAGE_BASE64}`}
                      alt=''
                      className='article_image'
                      style={{ width: '100%', maxWidth: '100%' }}
                    />
                  )}
                </Grid>
              )}
            </Grid>
            {(article?.DESCRIPTION_HTMLSHORT || article?.DESCRIPTION_HTMLLONG) && (
              <div
                className='article-card__text-wrapper'
                dangerouslySetInnerHTML={{
                  __html: `${article?.DESCRIPTION_HTMLSHORT || article?.DESCRIPTION_HTMLLONG}`,
                }}
              />
            )}
            {article?.audio && (
              <div style={{ marginBottom: '0.5rem', maxWidth: '100%' }} onClick={handleAudio}>
                <AudioPlayer />
              </div>
            )}
            {!displayGrid && (article?.IMAGE_SMALL_BASE64 || article?.IMAGE_BASE64) && (
              <div style={{ width: '100%', maxWidth: '100%' }}>
                <img
                  src={`data:image/png;base64, ${article?.IMAGE_SMALL_BASE64 || article?.IMAGE_BASE64}`}
                  alt=''
                  style={{ width: '100%', maxWidth: '100%', maxHeight: '18rem', marginBottom: '1rem' }}
                />
              </div>
            )}
            <div className='article-card_info-wrapper'>
              <span className='article-card_text-secondary'>
                {convertDate(Math.floor(+new Date() / 1000))} &middot; 5 mins to read{' '}
                <span className='article-card_button' onClick={addBookmark}>
                  <span style={{ color: 'rgba(44, 54, 79, 0.5)' }}>&middot; </span>
                  <BookmarkIcon /> Add to bookmarks
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teaser;
