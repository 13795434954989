import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { ReactComponent as ChevronDownIcon } from '../../media/icons/chevron-down-filled.svg';
import { ReactComponent as CheckedIcon } from '../../media/icons/checked.svg';
import { ReactComponent as CheckboxBlankIcon } from '../../media/icons/checkbox-blank.svg';
import { ReactComponent as FacebookIcon } from '../../media/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../media/icons/twitter.svg';
import { ReactComponent as PrintIcon } from '../../media/icons/print.svg';
import { ReactComponent as MailIcon } from '../../media/icons/mail.svg';

interface Props {}

const SignUp = (props: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  const history = useHistory();

  const [agreed, setAgreed] = useState<boolean>(false);

  return (
    <div className='auth-wrapper'>
      <Container maxWidth='md'>
        <div className='page-title-wrapper'>
          <h2 className='page-title'>Registrierung</h2>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Home page
          </Link>
        </div>
        <div className='section__light'>
          <p className='auth_text-main'>
            Registrierte Nutzer haben Zugriff auf zusätzliche Infos und Services von MMI sowie auf den geschlossenen
            Bereich unserer Webseite Gelbe Liste Online.
          </p>
          <p className='auth_text-bold'>Hinweis: Anmeldung mit Gelbe Liste Online Zugangsdaten möglich</p>
          <p className='auth_text-main'>
            Wenn Sie bereits ein Nutzerkonto für unser Portal{' '}
            <a className='auth_link' href='#'>
              Gelbe Liste Online
            </a>{' '}
            haben, können Sie sich mit diesen Zugangsdaten direkt{' '}
            <a className='auth_link' href='#'>
              hier einloggen
            </a>
            . Nach einer Registrierung können Sie sich mit Ihren Zugangsdaten dann auch jederzeit auf unserer Webseite{' '}
            <a className='auth_link' href='#'>
              Gelbe Liste Online
            </a>{' '}
            anmelden.
          </p>

          <form className='auth_form' onSubmit={() => history.push('profile')}>
            <Grid container spacing={deviceXs ? 2 : 4}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={['Herr', 'Frau']}
                  getOptionLabel={(option) => option}
                  popupIcon={<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}><ChevronDownIcon style={{ width: '10px', height: '10px' }} /></div>}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Anrede' variant='outlined' size='small' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={['Dr. Med']}
                  getOptionLabel={(option) => option}
                  popupIcon={<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}><ChevronDownIcon style={{ width: '10px', height: '10px' }} /></div>}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Education' variant='outlined' size='small' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='First name' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Last name' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Postleitzahl' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Address' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label='Beruf' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Nutzername' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Passwort' type='password' variant='outlined' size='small' />
              </Grid>
            </Grid>
            <div className='auth_checkbox-wrapper'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreed}
                    onChange={() => setAgreed(!agreed)}
                    icon={<CheckboxBlankIcon />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
                label={
                  <span className='auth_checkbox-label'>
                    Ich akzeptiere die{' '}
                    <a className='auth_link' href='#'>
                      Nutzungsbedingungen
                    </a>{' '}
                    und{' '}
                    <a className='auth_link' href='#'>
                      Datenschutzerklärung
                    </a>
                    .*
                  </span>
                }
              />
            </div>
            <Button color='primary' variant='contained' type='submit' size='large' style={{ marginTop: '3.5rem' }}>
              Registrieren
            </Button>
          </form>
        </div>
      </Container>
      <Container maxWidth='md'>
        <div className='auth_social-wrapper'>
          <FacebookIcon className='auth_social-button' />
          <TwitterIcon className='auth_social-button' />
          <PrintIcon className='auth_social-button' />
          <MailIcon className='auth_social-button' />
        </div>

        <p className='auth_text-secondary'>
          Der Schutz Ihrer persönlichen Daten ist uns wichtig. Sie haben jederzeit das Recht, Ihre Daten bei uns
          einzusehen, sperren oder löschen zu lassen und Ihre Zustimmung zu unserer Datenschutzerkärung zu widerrufen.
          Ihre Daten werden dazu verwendet, redaktionelle Inhalte, Produktinformationen und Services den hierfür
          berechtigten Berufsgruppen gemäß Heilmittelwerbegesetz bereitzustellen. Für Details zu Zweck und Verwendung
          Ihrer Daten beachten Sie bitte unsere{' '}
          <a className='auth_link' href='#'>
            Datenschutzerklärung
          </a>{' '}
          und wenden Sie sich bei Fragen zum Datenschutz an{' '}
          <a className='auth_link' href={`mailto:datenschutz@mmi.de`}>
            datenschutz@mmi.de
          </a>
          .
        </p>
      </Container>
    </div>
  );
};

export default SignUp;
