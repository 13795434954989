import React, { createContext, useState } from 'react';
import { logIn, logOut, getSessionId } from './actions/auth';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(false);
  const [fetched, setFetched] = useState(false);

  const login = async ({ email, password }) => {
    const res = await logIn({ email, password });
    setIsLogged(true);
    return res;
  };
  const logout = async () => {
    setIsLogged(null);
    await logOut();
  };
  const getLogInfo = async () => {
    const token = await getSessionId();
    setIsLogged(!!token);
  };
  if (!fetched) {
    getLogInfo().then(() => setFetched(true));
  }
  return (
    <AuthContext.Provider
      value={{
        isLogged,
        login,
        logout,
        setIsLogged,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContextProvider };
