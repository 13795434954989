import React, { Fragment, useState } from 'react';
import { Container, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { IArticle } from '../types';
import ArticleCard from '../reusable/ArticleCard';

import colonoscopy from '../../media/placeholders/colonoscopy.png';
import doctor from '../../media/placeholders/doctor.png';
import protectiveWear from '../../media/placeholders/protective-wear.png';
import { Pagination } from '@material-ui/lab';

interface Props {}

const SeeAllNews = (props: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [page, setPage] = useState<number>(1);

  const rowsPerPage: number = 10;

  const news: IArticle[] = [
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
  ];

  return (
    <div className='auth-wrapper'>
      <Container maxWidth='md'>
        <div className='page-title-wrapper'>
          <h2 className='page-title'>News</h2>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Home page
          </Link>
        </div>
        <div className='section__light'>
          {news
            .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
            .map((article: IArticle, key) => (
              <Fragment key={key}>
                {key !== 0 && <Divider style={{ margin: deviceSm ? '1rem 0' : '2rem 0' }} />}
                <ArticleCard article={article} displayGrid={true} />
              </Fragment>
            ))}
        </div>
      </Container>
      <Container maxWidth='md'>
        <div className='pagination-wrapper'>
          <Pagination
            count={Math.ceil(news.length / rowsPerPage)}
            page={page}
            onChange={(e, value) => setPage(value)}
            shape='rounded'
            size='small'
          />
        </div>
      </Container>
    </div>
  );
};

export default SeeAllNews;
