import React, { Fragment } from 'react';
import ArticleCard from '../reusable/ArticleCard';
import { IArticle } from '../types';
import { Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface Props {}

const Reports = (props: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const articles: IArticle[] = [
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
  ];

  return (
    <div>
      <div className='section_title-wrapper'>
        <h3 className='section_title'>Case reports</h3>
        <span className='see-all-button action-button__primary' onClick={() => history.push('reports/all')}>
          See All
        </span>
      </div>
      {articles.slice(0, deviceSm ? 2 : 3).map((article: IArticle, key) => (
        <Fragment key={key}>
          {key !== 0 && <Divider style={{ margin: '1rem 0' }} />}
          <ArticleCard article={article} displayGrid={true} />
        </Fragment>
      ))}
    </div>
  );
};

export default Reports;
