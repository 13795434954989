import { Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronDownIcon } from '../../media/icons/chevron-down.svg';
import { IHub } from '../types';
import mri from '../../media/placeholders/mri.png';
import redHeart from '../../media/placeholders/red-heart.png';
import doctor from '../../media/placeholders/doctor.png';
import vaccine from '../../media/placeholders/vaccine.png';
import doctor1 from '../../media/placeholders/doctor1.png';
import stethoscope from '../../media/placeholders/stethoscope.png';
import pill from '../../media/placeholders/pill.png';
import microscope from '../../media/placeholders/microscope.png';
import HubCard from '../reusable/HubCard';

interface Props {}

const ContentHubs = (props: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const popularHubs: IHub[] = [
    {
      title: 'Tumor-Agnostic Tratment',
      notif: false,
      amount: 12,
      image: mri,
    },
    {
      title: 'Cardiology',
      notif: false,
      amount: 12,
      image: redHeart,
    },
    {
      title: 'Covid-19',
      notif: false,
      amount: 11,
      image: doctor,
    },
    {
      title: 'Pneumology',
      notif: true,
      amount: 18,
      image: stethoscope,
    },
  ];

  const latestHubs: IHub[] = [
    {
      title: 'Cancer Vaccines',
      notif: true,
      amount: 18,
      image: vaccine,
    },
    {
      title: 'Rare Diseases For All Specialist melbourne',
      notif: false,
      amount: 11,
      image: doctor1,
    },
    {
      title: 'Tumor-Agnostic Tratment',
      notif: false,
      amount: 12,
      image: mri,
    },
    {
      title: 'Cardiology',
      notif: false,
      amount: 12,
      image: redHeart,
    },
    {
      title: 'Covid-19',
      notif: false,
      amount: 11,
      image: doctor,
    },
    {
      title: 'Pneumology',
      notif: true,
      amount: 18,
      image: stethoscope,
    },
    {
      title: 'Oncolytic-Virus Tharaphy',
      notif: true,
      amount: 162,
      image: pill,
    },
    {
      title: 'T-Cell Tharaphy',
      notif: false,
      amount: 11,
      image: microscope,
    },
    {
      title: 'Cancer Vaccines',
      notif: true,
      amount: 18,
      image: vaccine,
    },
    {
      title: 'Tumor-Agnostic Tratment',
      notif: false,
      amount: 12,
      image: mri,
    },
    {
      title: 'Cardiology',
      notif: false,
      amount: 12,
      image: redHeart,
    },
    {
      title: 'Covid-19',
      notif: false,
      amount: 11,
      image: doctor,
    },
    {
      title: 'Pneumology',
      notif: true,
      amount: 18,
      image: stethoscope,
    },
    {
      title: 'Rare Diseases For All Specialist melbourne',
      notif: false,
      amount: 11,
      image: doctor1,
    },
    {
      title: 'Oncolytic-Virus Tharaphy',
      notif: true,
      amount: 162,
      image: pill,
    },
    {
      title: 'T-Cell Tharaphy',
      notif: false,
      amount: 11,
      image: microscope,
    },
    {
      title: 'Cancer Vaccines',
      notif: true,
      amount: 18,
      image: vaccine,
    },
    {
      title: 'Rare Diseases For All Specialist melbourne',
      notif: false,
      amount: 11,
      image: doctor1,
    },
    {
      title: 'Oncolytic-Virus Tharaphy',
      notif: true,
      amount: 162,
      image: pill,
    },
    {
      title: 'T-Cell Tharaphy',
      notif: false,
      amount: 11,
      image: microscope,
    },
  ];

  return (
    <div>
      <div className='page-title-wrapper'>
        <h2 className='page-title'>Content Hubs</h2>
        <Link to='/' className='home-page-button'>
          <ChevronDownIcon style={{ transform: 'rotate(90deg)' }} /> Home page
        </Link>
      </div>
      <div className='section__light'>
        <Container maxWidth='lg'>
          <div className='section_title-wrapper'>
            <h3 className='section_title'>Most popular</h3>
          </div>
          <Grid container spacing={deviceSm ? 2 : 4}>
            {popularHubs.map((hub: IHub, key) => (
              <Grid item xs={6} sm={3} key={key}>
                <HubCard large={false} hub={hub} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {latestHubs.map((hub: IHub, key) => (
              <Grid item xs={6} sm={3} key={key}>
                <HubCard large={false} hub={hub} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default ContentHubs;
