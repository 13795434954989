import axiosInstance from '../../helpers/axiosInstance';
import { AxiosResponse } from 'axios';

export const logOut = () => {
  localStorage.removeItem('sessionId');
  return Promise.resolve();
};

export const getSessionId = async () => {
  let sessionId: string | null = JSON.parse(localStorage.getItem('sessionId') || '{}');

  if (typeof sessionId !== 'string') {
    // if no sessionId in localStorage then the user must enter their credentials to proceed
    return Promise.resolve(null);
  } else {
    // sessionId is up to date
    return new Promise((resolve, reject) => resolve(sessionId));
  }
};

export const logIn = ({ email, password }: { email: string; password: string }) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(
        '/medic/1/um/login',
        { partnerid: 'P6sqagav4gDxBXEY', moduleparameters: { username: email, password: password } },
        {
          headers: {
            'Content-Type': 'text/plain',
          },
        }
      )
      .then(async (res: AxiosResponse) => {
        const sessionId: string = await res.data?.RESULTOBJECT;
        localStorage.setItem('sessionId', JSON.stringify(sessionId));
        getSessionId()
          .then(() => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

// export const register = async ({ name, email, password, confirmPassword }) => {
//   try {
//     const res = await axiosInstance.post('/auth/register', { name, email, password, confirmPassword });
//     return res;
//   } catch (err) {
//     return err;
//   }
// };
