import React, { useState, useRef, ChangeEvent, MouseEvent, useEffect } from 'react';
import talkingHeads from '../../media/placeholders/talking-heads_burning-down-the-house.mp3';
import { ReactComponent as PodcastIcon } from '../../media/icons/podcast.svg';
import { PauseRounded, PlayArrowRounded } from '@material-ui/icons';

interface Props {
  src?: any;
}

const AudioPlayer = ({ src }: Props) => {
  const [paused, setPaused] = useState<boolean>(true);
  // const [muted, setMuted] = useState<boolean>(false);
  // const [volume, setVolume] = useState<number>(100);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<any>(0);
  const [processValue, setProcessValue] = useState<any>(0);

  const audioRef = useRef<HTMLAudioElement>(null);
  const progressRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const progressNode = progressRef.current;
    if (progressNode) {
      progressNode.style.background =
        'linear-gradient(to right, #fff 0%, #fff ' +
        progressNode.value +
        '%, rgba(255, 255, 255, 0.4) ' +
        progressNode.value +
        '%, rgba(255, 255, 255, 0.4) 100%)';
    }
    setProcessValue((100 / duration) * currentTime);
    if (duration === currentTime) setPaused(true);
  }, [duration, currentTime]);

  useEffect(() => {
    const node = audioRef.current;
    if (node && !paused) {
      setInterval(() => setCurrentTime(node.currentTime), 10);
    }
  }, [paused]);

  const setMetadata = (e: any) => {
    setDuration(e.target.duration);
    setCurrentTime(e.target.currentTime);
    setProcessValue((100 / e.target.duration) * e.target.currentTime);
  };

  const handlePlay = (e: MouseEvent<HTMLButtonElement>) => {
    console.log(audioRef.current);
    if (audioRef.current) {
      if (paused) {
        audioRef.current.play();
        setPaused(false);
      } else {
        audioRef.current.pause();
        setPaused(true);
      }
    }
  };

  const handleRewind = (e: ChangeEvent<HTMLInputElement>) => {
    const node = audioRef.current;
    if (node) {
      setCurrentTime((duration / 100) * parseInt(e.target.value, 10));
      node.currentTime = (duration / 100) * parseInt(e.target.value, 10);
    }

    const progressNode = progressRef.current;
    if (progressNode) {
      progressNode.style.background =
        'linear-gradient(to right, #fff 0%, #fff ' +
        progressNode.value +
        '%, rgba(255, 255, 255, 0.4) ' +
        progressNode.value +
        '%, rgba(255, 255, 255, 0.4) 100%)';
    }
  };

  const convertSeconds = (secs: number) => {
    let time = new Date(secs * 1000).toISOString().substr(14, 5);
    return time;
  };

  return (
    <div id='audio-player'>
      <audio ref={audioRef} onLoadedMetadata={setMetadata}>
        <source src={src ? src : talkingHeads} type='audio/mp3' />
        <p>
          Your browser doesn't support HTML5 audio. Here is a <a href='#'>link to the audio</a> instead.
        </p>
      </audio>
      <div
        style={{
          width: '100%',
          height: '50px',
          background: '#3F4452',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 4px',
        }}
      >
        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <PodcastIcon style={{ minWidth: '1.5rem' }} />
        </div>
        <button className='control-btn' style={{ margin: 0 }} type='button' data-state='play' onClick={handlePlay}>
          {paused ? <PlayArrowRounded fontSize='large' /> : <PauseRounded fontSize='large' />}
        </button>
        <input
          ref={progressRef}
          value={processValue}
          type='range'
          min='0'
          max='100'
          step='0.1'
          onChange={handleRewind}
        />
        <span className='media-time'>
          {convertSeconds(currentTime)} / {convertSeconds(duration)}
        </span>
      </div>
    </div>
  );
};

export default AudioPlayer;
