import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { ReactComponent as StethoscopeIcon } from '../../media/icons/stethoscope.svg';
import { ReactComponent as FirstAidKitIcon } from '../../media/icons/first-aid-kit.svg';
import { ReactComponent as PieChartIcon } from '../../media/icons/pie-chart.svg';
import { ReactComponent as VirusIcon } from '../../media/icons/virus.svg';
import { ReactComponent as NewspaperIcon } from '../../media/icons/newspaper.svg';
import { ReactComponent as DoctorIcon } from '../../media/icons/doctor.svg';
import { ReactComponent as PatientIcon } from '../../media/icons/patient.svg';
import { IArticle } from '../types';
import ArticleCard from '../reusable/ArticleCard';
import pen from '../../media/placeholders/pen.png';
import _ from 'lodash';
import { getTeaser } from '../../context/actions/article';
import { AxiosResponse } from 'axios';
import Teaser from '../reusable/Teaser';

const VerticalTabs = withStyles({
  indicator: {
    minWidth: '3px',
    right: '-2px',
  },
  root: {
    overflow: 'visible !important',
  },
  fixed: {
    overflow: 'visible !important',
  },
})(Tabs);

interface Props {
  displayGrid: boolean;
  currentTab: string;
  setCurrentTab: Dispatch<SetStateAction<string>>;
}

const ArticlesScroll = ({ displayGrid, currentTab, setCurrentTab }: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  const fakeArticles = [
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo!',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem harum voluptas sint provident? Ratione, modi?',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus beatae minima a eligendi esse optio voluptatum commodi, at accusamus dolorem, possimus dignissimos officiis!',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia fuga vero consequatur assumenda, aperiam incidunt necessitatibus maxime nesciunt!',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eius facilis neque quidem officiis id maxime porro, quia dicta soluta tempora. Voluptatem.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias ab aspernatur nostrum recusandae incidunt, fugiat magnam amet vel dolorum magni et commodi sunt quas, possimus, maiores distinctio obcaecati dolorem.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Non error voluptates aspernatur excepturi recusandae nulla sit amet, explicabo ea inventore assumenda expedita.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet perferendis quas eos iste cumque asperiores odio earum, vitae placeat reprehenderit illo quibusdam eveniet? Ut, dolores.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel suscipit nobis impedit nisi?',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum, minus. Eaque deserunt dolore laborum, minima non similique!',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam vel maxime animi voluptates in impedit, perferendis distinctio magni quam aut?',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur numquam perferendis sed eveniet aliquam esse voluptate recusandae aliquid possimus.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde nostrum dolores nulla neque atque cumque vel optio aliquam dicta. Nisi est officia ad?',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
    },
  ];

  const [teasers, setTeasers] = useState<any[]>(fakeArticles);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchTeasers = async (ids: string[]) => {
    setIsLoading(true);

    let arr = [];
    for (let i = 0; ids.length > i; i++) {
      let teaser = await getTeaser(ids[i]).then((res: any) => res?.data?.RESULTOBJECT);
      arr.push(teaser);
    }
    setTeasers(arr);

    setIsLoading(false);
  };

  useEffect(() => {
    setTeasers([]);
    if (currentTab === 'therapy') {
      fetchTeasers(['a614fd7c-0be0-4e0b-a550-e2879d648f26', '6742cccf-ac06-4d58-9e44-c7bf3239c22b']);
    } else if (currentTab === 'news') {
      fetchTeasers([
        '7e814ad5-8116-4b44-8943-bbc72a7b8c05',
        'b8a9b45b-22d4-4572-a106-5631ff2e0ea4',
        '255c267e-d4fb-4d99-83c4-3b09d503bbe5',
        '7fb13462-c8a1-4ffb-8e60-f95cd315dab2',
        '0c2e0e71-6fd6-4dc1-912b-97743a58fb91',
        '646c4dbc-d7b9-45f1-9cec-b40a85f8a80a',
        '2d313b07-60b0-4824-8462-c15bb0544171',
      ]);
    } else if (currentTab === 'reports') {
      fetchTeasers(['a9575706-d869-43d9-94b8-63f9869dde21']);
    } else if (currentTab === 'patient') {
      fetchTeasers(['451678cd-23f5-4fcb-bff8-62278eff9a31']);
    } else {
      setTeasers(_.shuffle([...fakeArticles]));
    }
  }, [currentTab]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setCurrentTab(newValue);
  };

  return !displayGrid ? (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Divider style={{ margin: '0 0 2rem 0' }} />
      <Grid container spacing={deviceXs ? 2 : 4} justify='center'>
        <Grid item xs={12} md={9}>
          <ArticleCard
            article={{
              author: 'Bachanova',
              area: 'Theraphy guidlines',
              tags: ['Covid-19', 'Cardiology'],
              title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
              text:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
              date: Math.floor(+new Date() / 1000),
              estimate: 7,
              image: pen,
            }}
            displayGrid={displayGrid}
          />
        </Grid>
      </Grid>
    </Container>
  ) : (
    <div className='section__dark' style={{ padding: 0 }}>
      <Container id='articles-scroll' maxWidth='lg' disableGutters={deviceXs}>
        <div style={{ display: 'flex' }}>
          <VerticalTabs
            orientation='vertical'
            value={currentTab}
            onChange={handleTabChange}
            style={{ borderRight: '1px solid rgba(225, 228, 233, 0.7)', padding: '2rem 0' }}
          >
            <Tab
              value='diagnostic'
              label={
                <div className='tab-wrapper'>
                  <Tooltip title={deviceXs ? 'Diagnostic' : ''} placement='right' arrow>
                    <div className='tab-icon-wrapper'>
                      <StethoscopeIcon />
                    </div>
                  </Tooltip>
                  {!deviceXs && <span>Diagnostic</span>}
                </div>
              }
              disableRipple
            />
            <Tab
              value='therapy'
              label={
                <div className='tab-wrapper'>
                  <Tooltip title={deviceXs ? 'Theraphy' : ''} placement='right' arrow>
                    <div className='tab-icon-wrapper'>
                      <FirstAidKitIcon />
                    </div>
                  </Tooltip>
                  {!deviceXs && <span>Theraphy</span>}
                </div>
              }
              disableRipple
            />
            <Tab
              value='reports'
              label={
                <div className='tab-wrapper'>
                  <Tooltip title={deviceXs ? 'Case reports' : ''} placement='right' arrow>
                    <div className='tab-icon-wrapper'>
                      <PieChartIcon />
                    </div>
                  </Tooltip>
                  {!deviceXs && <span>Case reports</span>}
                </div>
              }
              disableRipple
            />
            <Tab
              value='diseases'
              label={
                <div className='tab-wrapper'>
                  <Tooltip title={deviceXs ? 'Diseases' : ''} placement='right' arrow>
                    <div className='tab-icon-wrapper'>
                      <VirusIcon />
                    </div>
                  </Tooltip>
                  {!deviceXs && <span>Diseases</span>}
                </div>
              }
              disableRipple
            />
            <Tab
              value='news'
              label={
                <div className='tab-wrapper'>
                  <Tooltip title={deviceXs ? 'News' : ''} placement='right' arrow>
                    <div className='tab-icon-wrapper'>
                      <NewspaperIcon />
                    </div>
                  </Tooltip>
                  {!deviceXs && <span>News</span>}
                </div>
              }
              disableRipple
            />
            <Tab
              value='md'
              label={
                <div className='tab-wrapper'>
                  <Tooltip title={deviceXs ? 'Info for MD' : ''} placement='right' arrow>
                    <div className='tab-icon-wrapper'>
                      <DoctorIcon />
                    </div>
                  </Tooltip>
                  {!deviceXs && <span>Info for MD</span>}
                </div>
              }
              disableRipple
            />
            <Tab
              value='patient'
              label={
                <div className='tab-wrapper'>
                  <Tooltip title={deviceXs ? 'Info for patient' : ''} placement='right' arrow>
                    <div className='tab-icon-wrapper'>
                      <PatientIcon />
                    </div>
                  </Tooltip>
                  {!deviceXs && <span>Info for patient</span>}
                </div>
              }
              disableRipple
            />
          </VerticalTabs>
          <div style={{ margin: '2rem', position: 'relative', width: '100%' }}>
            <div
              style={{
                position: 'absolute',
                top: '-1px',
                left: 0,
                right: '5px',
                height: '2rem',
                background: 'linear-gradient(180deg, #fafafa 0%, rgba(250,250,250,0) 100%)',
              }}
            ></div>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: '5px',
                height: '2rem',
                background: 'linear-gradient(0deg, #fafafa 0%, rgba(250,250,250,0) 100%)',
              }}
            ></div>
            {isLoading ? (
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div className='articles-scroll-wrapper'>
                <Grid container spacing={deviceXs ? 2 : 4}>
                  {teasers.map((article: any, key: number) => (
                    <Grid item xs={12} md={6} key={key}>
                      {currentTab === 'therapy' ||
                      currentTab === 'news' ||
                      currentTab === 'reports' ||
                      currentTab === 'patient' ? (
                        <Teaser article={article} displayGrid={true} />
                      ) : (
                        <ArticleCard article={article} displayGrid={true} />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ArticlesScroll;
