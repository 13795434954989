import React, { useContext, useEffect } from 'react';
import './App.css';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Nav from './components/Nav';
import Home from './components/home/Home';
import Hub from './components/hub/Hub';
import ContentHubs from './components/home/ContentHubs';
import Article from './components/reusable/Article';
import ScrollToTop from './routers/ScrollToTop';
import Search from './components/search/Search';
import Media from './components/reusable/Media';
import SeeAllExperts from './components/home/SeeAllExperts';
import SeeAllReports from './components/home/SeeAllReports';
import SeeAllInterviews from './components/home/SeeAllInterviews';
import SeeAllNews from './components/home/SeeAllNews';
import SignUp from './components/auth/SignUp';
import SignIn from './components/auth/SignIn';
import ChangePassword from './components/profile/ChangePassword';
import Profile from './components/profile/Profile';
import { AuthContext } from './context/AuthContext';
import ArticleContent from './components/reusable/ArticleContent';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#AE3224',
      contrastText: '#fff',
    },
    text: {
      primary: '#2c364f',
    },
    // secondary: {
    //   main: '#AE3224',
    // },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorDefault: {
        color: '#2c364f',
        backgroundColor: 'rgba(44, 54, 79, 0.1)',
      },
    },
    MuiToolbar: {
      root: {
        justifyContent: 'space-between',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
      label: {
        color: 'inherit',
        textTransform: 'capitalize',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff',
        borderRadius: 5,
      },
      notchedOutline: {
        borderColor: '#D1D8E1',
        '&:hover': {
          borderColor: '#2C364F',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 2px 4px rgba(44, 54, 79, 0.1)',
      },
    },
    MuiChip: {
      sizeSmall: {
        height: '1.125rem',
      },
      labelSmall: {
        fontSize: '0.625rem',
        lineHeight: '1.125rem',
      },
      root: {
        height: '2.125rem',
      },
      colorPrimary: {
        '& > *': {
          color: '#fff',
        },
      },
      label: {
        fontSize: '0.875rem',
        lineHeight: '2.125rem',
      },
      outlined: {
        margin: '0 -1px',
        borderColor: '#E1E4E9',
        backgroundColor: 'rgba(225, 228, 233, 0.3)',
      },
    },
    MuiTabs: {
      indicator: {
        height: '1px',
        backgroundColor: '#2C364F',
        minWidth: '3px',
      },
    },
    MuiTab: {
      root: {
        minWidth: '0px !important',
        fontSize: '0.875rem',
        lineHeight: 1,
        overflow: 'visible',
      },
      textColorInherit: {
        opacity: 0.5,
      },
      wrapper: {
        alignItems: 'flex-start',
      },
    },
    MuiInputAdornment: {
      root: {
        minHeight: '100%',
        height: '100%',
      },
    },
  },
});

const App = () => {
  const { isLogged } = useContext(AuthContext);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop>
          <Nav>
            {!!localStorage.getItem('sessionId') ? (
              <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/profile' component={Profile} />
                <Route exact path='/profile/change-password' component={ChangePassword} />
                <Route exact path='/hub' component={Hub} />
                <Route exact path='/hubs/all' component={ContentHubs} />
                <Route exact path='/experts/all' component={SeeAllExperts} />
                <Route exact path='/reports/all' component={SeeAllReports} />
                <Route exact path='/interviews/all' component={SeeAllInterviews} />
                <Route exact path='/news/all' component={SeeAllNews} />
                <Route exact path='/articles/id' component={Article} />
                <Route exact path='/articles/:id' component={ArticleContent} />
                <Route exact path='/video/id' render={() => <Media video />} />
                <Route exact path='/audio/id' component={Media} />
                <Route exact path='/search/:tag' component={Search} />
                <Redirect to='/' />
              </Switch>
            ) : (
              <Switch>
                <Route exact path='/auth/signup' component={SignUp} />
                <Route exact path='/auth/signin' component={SignIn} />
                <Redirect to='/auth/signin' />
              </Switch>
            )}
          </Nav>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
