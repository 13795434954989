import { Card, CardActionArea, CardMedia, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { MouseEvent } from 'react';
import image from '../../media/placeholders/laptop.png';
import audioHub from '../../media/placeholders/audio-hub.png';
import videoHub from '../../media/placeholders/conference.png';
import { ReactComponent as PlayIcon } from '../../media/icons/play.svg';
import { ReactComponent as BellIcon } from '../../media/icons/bell.svg';
import { ReactComponent as BellFilledIcon } from '../../media/icons/bell-filled.svg';
import { IHub } from '../types';
import { useHistory } from 'react-router-dom';

interface Props {
  large: boolean;
  hub: IHub;
}

const HubCard = ({ hub, large }: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const handleNotification = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
  };

  return (
    <Card
      elevation={0}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: deviceXs ? '20rem' : !large || (hub.video && deviceXs) ? '10rem' : '25rem',
        position: 'relative',
      }}
      onClick={() => history.push(hub.video ? '/video/id' : '/hub')}
    >
      <CardActionArea style={{ height: '100%' }}>
        <CardMedia
          component='img'
          alt='Cardiology'
          height='100%'
          src={hub.image ? hub.image : hub.audio ? audioHub : hub.video ? videoHub : image}
          title='Cardiology'
        />
        <div className='card_gradient'>
          {hub.video && (
            <div
              className='play-icon-wrapper'
              style={
                large
                  ? { width: '5rem', height: '5rem', borderRadius: '2.5rem' }
                  : { width: '2.2rem', height: '2.2rem', borderRadius: '1.1rem' }
              }
            >
              <PlayIcon style={large ? { width: '2rem', height: '2rem' } : { width: '1rem', height: '1rem' }} />
            </div>
          )}
        </div>
        {!hub.video && !hub.audio ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              position: 'absolute',
              top: large ? '1rem' : '.5rem',
              right: large ? '1rem' : '.5rem',
            }}
          >
            {hub.title && (
              <>
                {' '}
                {hub.notif ? (
                  <BellFilledIcon className='icon-button__light' onClick={handleNotification} />
                ) : (
                  <BellIcon className='icon-button__light' onClick={handleNotification} />
                )}{' '}
              </>
            )}
            <div
              style={{
                marginLeft: large ? '1rem' : '.5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#2C364F',
                color: '#fff',
                borderRadius: '0.75rem',
                minWidth: '1.5rem',
                height: '1.5rem',
              }}
            >
              <span style={{ color: '#fff' }}>{hub.amount}</span>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              overflow: 'hidden',
              padding: large ? '1rem' : '.5rem',
            }}
          >
            <Typography
              variant='body1'
              noWrap
              style={{
                fontSize: large ? '0.875rem' : '0.625rem',
                lineHeight: large ? '0.875rem' : '0.625rem',
                color: '#fff',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {hub.title}
            </Typography>
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            overflow: 'hidden',
            padding: large ? '1rem' : '.5rem',
          }}
        >
          {!hub.video && !hub.audio ? (
            <Typography
              variant='body1'
              noWrap
              style={{
                fontSize: large ? '1.375rem' : '1rem',
                lineHeight: large ? '1.375rem' : '1rem',
                color: '#fff',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {hub.title ? hub.title : hub.author}
            </Typography>
          ) : (
            <>
              <Typography
                variant='body1'
                noWrap
                style={{
                  fontSize: large ? '1' : '0.875rem',
                  color: '#fff',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {hub.contentTitle}
              </Typography>
              <Typography
                variant='body1'
                noWrap
                style={{
                  fontSize: large ? '0.875rem' : '0.625rem',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  color: '#fff',
                  opacity: 0.5,
                }}
              >
                {hub.contentTitle}
              </Typography>
            </>
          )}
        </div>
      </CardActionArea>
    </Card>
  );
};

export default HubCard;
