import React, { useState } from 'react';
import { Chip, Collapse, Container, useMediaQuery, useTheme } from '@material-ui/core';
import { ITopic } from '../types';
import { ReactComponent as CheckIcon } from '../../media/icons/check.svg';
import { ReactComponent as SquareIcon } from '../../media/icons/square.svg';
import { ReactComponent as ChevronDownIcon } from '../../media/icons/chevron-down.svg';

interface Props {
  topics: ITopic[];
  setTopics: any;
}

const Topics = ({ topics, setTopics }: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [showMore, setShowMore] = useState<boolean>(deviceXs ? false : true);

  const handleCheck = (topic: ITopic) => {
    let topicsCopy = [...topics];
    let targetTopic = topicsCopy.find((el: ITopic) => el.id === topic.id);
    if (targetTopic) targetTopic.selected = !targetTopic.selected;
    setTopics(topicsCopy);
  };

  return (
    <div className='section__dark'>
      <Container maxWidth='lg'>
        <div className='section_title-wrapper'>
          <h3 className='section_title'>Topics to follow</h3>
          {deviceXs && (
            <ChevronDownIcon
              width='0.75rem'
              height='0.75rem'
              className='icon-button__default'
              style={{ marginLeft: '0.5rem', transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)' }}
              onClick={() => setShowMore(!showMore)}
            />
          )}
        </div>
        <Collapse in={showMore} collapsedHeight='6.25rem'>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '0 -0.25rem',
            }}
          >
            {topics.map((topic: ITopic, key) => (
              <div key={key} style={{ margin: '0.5rem .25rem' }}>
                <Chip
                  color={topic.selected ? 'primary' : 'default'}
                  variant={topic.selected ? 'default' : 'outlined'}
                  label={topic.name}
                  clickable
                  deleteIcon={
                    topic.selected ? <CheckIcon className='chip-icon' /> : <SquareIcon className='chip-icon' />
                  }
                  onClick={() => handleCheck(topic)}
                  onDelete={() => handleCheck(topic)}
                />
              </div>
            ))}
          </div>
        </Collapse>
      </Container>
    </div>
  );
};

export default Topics;
