import { Container, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { IArticle } from '../types';
import doctor1 from '../../media/placeholders/doctor-photo1.png';
import doctor2 from '../../media/placeholders/doctor-photo2.png';
import doctor3 from '../../media/placeholders/doctor-photo3.png';
import ArticleCard from '../reusable/ArticleCard';
import { Pagination } from '@material-ui/lab';

interface Props {}

const SeeAllExperts = (props: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [page, setPage] = useState<number>(1);

  const rowsPerPage: number = 10;

  const articles: IArticle[] = [
    {
      author: 'Bachanov',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor1,
    },
    {
      author: 'Bachanova',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor2,
    },
    {
      author: 'Doe',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor3,
    },
    {
      author: 'Smith',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor2,
    },
    {
      author: 'Smithers',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor1,
    },
    {
      author: 'Barabanova',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor3,
    },
    {
      author: 'Bachanov',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor1,
    },
    {
      author: 'Bachanova',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor2,
    },
    {
      author: 'Doe',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor3,
    },
    {
      author: 'Smith',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor2,
    },
    {
      author: 'Smithers',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor1,
    },
    {
      author: 'Barabanova',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor3,
    },
    {
      author: 'Bachanov',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor1,
    },
    {
      author: 'Bachanova',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor2,
    },
    {
      author: 'Doe',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor3,
    },
    {
      author: 'Smith',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor2,
    },
    {
      author: 'Smithers',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor1,
    },
    {
      author: 'Barabanova',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor3,
    },
    {
      author: 'Bachanova',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor2,
    },
    {
      author: 'Doe',
      text:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus beatae odio ad obcaecati dolorum qui quaerat dolore, quo, libero, ab itaque provident illo impedit sunt at? Accusantium.',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      avatar: doctor3,
    },
  ];

  return (
    <div className='auth-wrapper'>
      <Container maxWidth='md'>
        <div className='page-title-wrapper'>
          <h2 className='page-title'>Experts</h2>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Home page
          </Link>
        </div>
        <div className='section__light'>
          {articles
            .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
            .map((article: IArticle, key) => (
              <Fragment key={key}>
                {key !== 0 && <Divider style={{ margin: deviceSm ? '1rem 0' : '2rem 0' }} />}
                <ArticleCard key={key} article={article} />
              </Fragment>
            ))}
        </div>
      </Container>
      <Container maxWidth='md'>
        <div className='pagination-wrapper'>
          <Pagination
            count={Math.ceil(articles.length / rowsPerPage)}
            page={page}
            onChange={(e, value) => setPage(value)}
            shape='rounded'
            size='small'
          />
        </div>
      </Container>
    </div>
  );
};

export default SeeAllExperts;
