import axiosInstance from '../../helpers/axiosInstance';
import { AxiosResponse } from 'axios';

export const getTeaser = async (documentId: string) => {
  try {
    const sessionId = JSON.parse(localStorage.getItem('sessionId') || '');
    const res: AxiosResponse<any[]> = await axiosInstance.get(
      `/medic/1/dms/getDocument/id/{"partnerid": "P6sqagav4gDxBXEY","sessionid": "${sessionId}","moduleparameters": {"document": {"DOCUMENTID": "${documentId}"}}}`
    );
    return res;
  } catch (err) {
    return console.log('error', err);
  }
};

export const getArticle = async (documentId: string) => {
  try {
    const sessionId = JSON.parse(localStorage.getItem('sessionId') || '');
    const res: AxiosResponse<any[]> = await axiosInstance.get(
      `/medic/1/dms/FileManager/${documentId}/{"partnerid": "P6sqagav4gDxBXEY","sessionid": "${sessionId}"}}`
    );
    return res;
  } catch (err) {
    return console.log('error', err);
  }
};
