import React from 'react';
import { Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import colonoscopy from '../../media/placeholders/colonoscopy.png';
import doctor from '../../media/placeholders/doctor.png';
import protectiveWear from '../../media/placeholders/protective-wear.png';
import ArticleCard from '../reusable/ArticleCard';
import { IArticle } from '../types';
import { useHistory } from 'react-router-dom';

interface Props {}

const News = (props: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const articles = [
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: protectiveWear,
    },
  ];

  return (
    <div className='section__light'>
      <Container maxWidth='lg'>
        <div className='section_title-wrapper'>
          <h3 className='section_title'>News</h3>
          <span className='see-all-button action-button__primary' onClick={() => history.push('news/all')}>
            See All
          </span>
        </div>
        <Grid container spacing={deviceSm ? 2 : 4}>
          {articles.map((article: IArticle, key) => (
            <Grid key={key} item xs={12} md={6}>
              {key === 0 || (key === 1 && !deviceSm) ? null : (
                <Divider style={{ margin: deviceSm ? '0 0 1rem 0' : '0 0 2rem 0' }} />
              )}
              <ArticleCard article={article} displayGrid={true} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default News;
