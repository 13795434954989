import { Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import ArticleCard from '../reusable/ArticleCard';
import { IArticle } from '../types';
import doctor1 from '../../media/placeholders/doctor-glasses.png';
import doctor2 from '../../media/placeholders/female-doctor.png';
import doctor3 from '../../media/placeholders/female-doctor1.png';

interface Props {
  displayGrid: boolean;
}

const FromExperts = ({ displayGrid }: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  const articles = [
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: doctor1,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: doctor2,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      audio: true,
      avatar: doctor3,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      audio: true,
      avatar: doctor2,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: doctor2,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      audio: true,
      avatar: doctor1,
    },
  ];

  return displayGrid ? (
    <div className='section__light'>
      <Container maxWidth='lg'>
        {displayGrid && (
          <div className='section_title-wrapper'>
            <h3 className='section_title'>From experts</h3>
          </div>
        )}
        <Grid container spacing={deviceSm ? 2 : 4}>
          {articles.map((article: IArticle, key) => (
            <Grid key={key} item xs={12} md={6}>
              {key === 0 || (key === 1 && !deviceSm) ? null : (
                <Divider style={{ margin: deviceSm ? '0 0 1rem 0' : '0 0 2rem 0' }} />
              )}
              <ArticleCard article={article} displayGrid={true} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  ) : (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Divider style={{ margin: '0 0 2rem 0' }} />
      <Grid container spacing={deviceXs ? 2 : 4} justify='center'>
        <Grid item xs={12} md={9}>
          <ArticleCard article={articles[3]} displayGrid={displayGrid} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default FromExperts;
