import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { ReactComponent as ChevronDownIcon } from '../../media/icons/chevron-down-filled.svg';
import { ReactComponent as CheckedIcon } from '../../media/icons/checked.svg';
import { ReactComponent as CheckboxBlankIcon } from '../../media/icons/checkbox-blank.svg';
import { ReactComponent as FacebookIcon } from '../../media/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../media/icons/twitter.svg';
import { ReactComponent as PrintIcon } from '../../media/icons/print.svg';
import { ReactComponent as MailIcon } from '../../media/icons/mail.svg';
import News from '../home/News';

interface Props {}

const Profile = (props: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  const history = useHistory();

  const [checked, setChecked] = useState<number[]>([]);

  const [currentTab, setCurrentTab] = useState<number>(0);

  const news: string[] = [
    'Gelbe Liste Newsletter (7-täglich) – Nachrichten und Meldungen aus dem Arzneimittelmarkt',
    'Gelbe Liste Newsletter Dermatologie (monatlich) - Meldungen und Studien im Bereich Dermatologie',
    'Gelbe Liste Newsletter Gynäkologie (monatlich) - Meldungen und Studien im Bereich Frauenheilkunde',
    'Gelbe Liste Newsletter Kardiologie (14-täglich) - Nachrichten im Bereich Kardiologie',
    'Gelbe Liste Newsletter Neurologie (14-täglich) - Meldungen und Studien im Bereich Neurologie',
    'Gelbe Liste Newsletter Onkologie (7-täglich) - Meldungen und Studien im Bereich Krebsmedizin',
    'Gelbe Liste Newsletter Pneumologie (monatlich) - Meldungen und Studien im Bereich Lungenheilkunde',
    'GesundheitsLetter (14-täglich) – Gesundheitsratgeber und Nachrichten für Patienten',
    'MafoLetter (unregelmäßig) – Einladungen zu Umfragen oder Marktforschungsangeboten',
    'PartnerLetter (unregelmäßig) – Infos und News zu Produkten von Industriepartnern',
    'PraxisLetter (7-täglich) – Nachrichten und Praxistipps in Kooperation mit Medical Tribune',
    'Rote-Hand-Alarm - E-Mail-Benachrichtigung zu neuen Rote-Hand-Briefen',
  ];

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => setCurrentTab(newValue);

  const handleChecked = (key: number) => {
    let checkedCopy = [...checked];
    const index = checkedCopy.indexOf(key);
    if (index > -1) {
      checkedCopy.splice(index, 1);
      setChecked(checkedCopy);
    } else {
      setChecked([...checked, key]);
    }
  };

  return (
    <div>
      <Container maxWidth='md'>
        <div className='page-title-wrapper'>
          <h2 className='page-title'>Ihr Profil</h2>
          <Link to='/' className='breadcrumb-button'>
            <ChevronLeftIcon /> Home page
          </Link>
        </div>
        <p className='auth_text-bold' style={{ marginTop: '3rem' }}>
          Hier können Sie bequem Ihre persönlichen Daten bearbeiten und aktualisieren sowie unsere kostenlosen
          Newsletter bestellen und verwalten. Ihre Daten werden stets vertraulich behandelt und nicht zu Werbezwecken an
          Dritte weitergegeben.
        </p>
        <p className='auth_text-main'>
          Mit Ihrem Konto können Sie sich auch jederzeit auf unserer Webseite{' '}
          <a className='auth_link' href='#'>
            Gelbe Liste Online
          </a>{' '}
          anmelden.
        </p>

        <h3 className='profile_title'>Dr. med. Anna Schaefer</h3>
      </Container>

      <div className='section__dark' style={{ padding: 0, width: '100%' }}>
        <Container maxWidth='md'>
          <Tabs value={currentTab} onChange={handleTabChange} variant='scrollable' scrollButtons='off'>
            <Tab label='Profil' disableRipple />
            <Tab label='Newsletter' disableRipple />
          </Tabs>
        </Container>
      </div>

      <Container maxWidth='md'>
        {currentTab === 0 ? (
          <form className='auth_form' onSubmit={() => history.push('profile')}>
            <h5 className='profile_subtitle'>Zugangsdaten</h5>
            <Grid container spacing={deviceXs ? 2 : 4}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={['Herr', 'Frau']}
                  getOptionLabel={(option) => option}
                  popupIcon={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <ChevronDownIcon style={{ width: '10px', height: '10px' }} />
                    </div>
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Anrede' variant='outlined' size='small' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={['Dr. Med']}
                  getOptionLabel={(option) => option}
                  popupIcon={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <ChevronDownIcon style={{ width: '10px', height: '10px' }} />
                    </div>
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Education' variant='outlined' size='small' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='First name' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Last name' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='E-Mail (Nutzername)' variant='outlined' size='small' />
              </Grid>
            </Grid>

            <h5 className='profile_subtitle' style={{ marginTop: '3rem' }}>
              Adresse
            </h5>
            <Grid container spacing={deviceXs ? 2 : 4}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={[]}
                  getOptionLabel={(option) => option}
                  popupIcon={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <ChevronDownIcon style={{ width: '10px', height: '10px' }} />
                    </div>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Praxis/Klinik/Apotheke/Unternehmen'
                      variant='outlined'
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={[]}
                  getOptionLabel={(option) => option}
                  popupIcon={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <ChevronDownIcon style={{ width: '10px', height: '10px' }} />
                    </div>
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Straße und Hausnummer' variant='outlined' size='small' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Postleitzahl' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Work E-Mail' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={['Deutschland']}
                  getOptionLabel={(option) => option}
                  popupIcon={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <ChevronDownIcon style={{ width: '10px', height: '10px' }} />
                    </div>
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Land' variant='outlined' size='small' />
                  )}
                />
              </Grid>
            </Grid>

            <h5 className='profile_subtitle' style={{ marginTop: '3rem' }}>
              Beruf
            </h5>
            <Grid container spacing={deviceXs ? 2 : 4}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={[]}
                  getOptionLabel={(option) => option}
                  popupIcon={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <ChevronDownIcon style={{ width: '10px', height: '10px' }} />
                    </div>
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Beruf' variant='outlined' size='small' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={[]}
                  getOptionLabel={(option) => option}
                  popupIcon={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <ChevronDownIcon style={{ width: '10px', height: '10px' }} />
                    </div>
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Fachgebiet' variant='outlined' size='small' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={[]}
                  getOptionLabel={(option) => option}
                  popupIcon={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <ChevronDownIcon style={{ width: '10px', height: '10px' }} />
                    </div>
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Qualifikation' variant='outlined' size='small' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={[]}
                  getOptionLabel={(option) => option}
                  popupIcon={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                      }}
                    >
                      <ChevronDownIcon style={{ width: '10px', height: '10px' }} />
                    </div>
                  }
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Tätigkeitsbereich' variant='outlined' size='small' />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='LANR' variant='outlined' size='small' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='EFN' variant='outlined' size='small' />
              </Grid>
            </Grid>

            <Button color='primary' variant='contained' type='submit' size='large' style={{ marginTop: '3.5rem' }}>
              Änderungen Speichern
            </Button>
          </form>
        ) : (
          <div>
            <h5 className='profile_subtitle' style={{ marginTop: '3rem' }}>
              Newsletter
            </h5>
            <div>
              {news.map((item: string, key: number) => (
                <FormControlLabel
                  style={{ marginBottom: '1rem' }}
                  key={key}
                  control={
                    <Checkbox
                      checked={checked.includes(key)}
                      onChange={() => handleChecked(key)}
                      icon={<CheckboxBlankIcon />}
                      checkedIcon={<CheckedIcon />}
                    />
                  }
                  label={<span className='auth_checkbox-label'>{item}</span>}
                />
              ))}
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button color='primary' variant='contained' size='large' style={{ marginTop: '3.5rem' }}>
                Änderungen Speichern
              </Button>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Profile;
