import { Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Fragment } from 'react';
import HubCard from '../reusable/HubCard';
import doctorPortrait from '../../media/placeholders/doctor-portrait.png';
import ArticleCard from '../reusable/ArticleCard';
import protectiveWear from '../../media/placeholders/protective-wear.png';
import colonoscopy from '../../media/placeholders/colonoscopy.png';
import doctor from '../../media/placeholders/doctor.png';
import { IArticle } from '../types';

interface Props {
  displayGrid: boolean;
}

const Latest = ({ displayGrid }: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const deviceMd = useMediaQuery(theme.breakpoints.down('md'));

  const articles = [
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology', 'Therapy'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: colonoscopy,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      image: doctor,
    },
    {
      author: 'Bachanova',
      area: 'Theraphy guidlines',
      tags: ['Covid-19', 'Cardiology', 'Radiology'],
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      avatar: doctorPortrait,
      audio: true,
    },
  ];

  return (
    <div className='section__light'>
      <Container maxWidth='lg'>
        {displayGrid && (
          <div className='section_title-wrapper'>
            <h3 className='section_title'>Latest</h3>
          </div>
        )}
        <Grid container spacing={deviceSm ? 2 : 4} justify='center'>
          <Grid item xs={12} md={displayGrid ? 6 : 9}>
            <Grid container spacing={deviceSm ? 2 : 4}>
              <Grid item xs={12}>
                <HubCard
                  large={true}
                  hub={{
                    title: 'Covid-19',
                    image: doctorPortrait,
                    video: true,
                    contentTitle: 'Covid-19 and Oncology',
                    contentSubtitle: 'Bachanova et al.',
                  }}
                />
              </Grid>
              {!deviceSm && displayGrid && (
                <Grid item xs={12}>
                  <ArticleCard
                    article={{
                      author: 'Bachanova',
                      area: 'Theraphy guidlines',
                      tags: ['Covid-19', 'Cardiology', 'Radiology'],
                      title:
                        'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
                      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
                      date: Math.floor(+new Date() / 1000),
                      estimate: 7,
                      image: protectiveWear,
                    }}
                    displayGrid={true}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {displayGrid && (
            <Grid item xs={12} md={6}>
              {articles.map((article: IArticle, key) => (
                <Fragment key={key}>
                  {(key !== 0 || deviceSm) && <Divider style={{ margin: '1rem 0' }} />}
                  <ArticleCard article={article} displayGrid={true} />
                </Fragment>
              ))}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Latest;
