import { Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import logo1 from '../../media/placeholders/logo1.png';
import logo2 from '../../media/placeholders/logo2.png';
import logo3 from '../../media/placeholders/logo3.png';
import logo4 from '../../media/placeholders/logo4.png';
import pharmacy from '../../media/placeholders/pharmacy.png';
import chart from '../../media/placeholders/chart.png';
import chartLarge from '../../media/placeholders/chart-large.png';
import chart1 from '../../media/placeholders/chart2.png';
import drugDoctor from '../../media/placeholders/drug-doctor.png';
import ArticleCard from '../reusable/ArticleCard';
import { IArticle } from '../types';

interface Props {
  displayGrid: boolean;
}

const Pharma = ({ displayGrid }: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  const articles = [
    {
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      logo: logo1,
      image: pharmacy,
    },
    {
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: displayGrid
        ? 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum'
        : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      logo: logo2,
      image: displayGrid ? chart : chartLarge,
    },
    {
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      logo: logo3,
      image: chart1,
    },
    {
      title: 'Immuncheckpoint-Inhibitoren (Atezolizumab, Cemiplimab, Durvalumab): Änderung Produktinformation',
      text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
      date: Math.floor(+new Date() / 1000),
      estimate: 7,
      logo: logo4,
      image: drugDoctor,
    },
  ];

  return displayGrid ? (
    <div className='section__light'>
      <Container maxWidth='lg'>
        <div className='section_title-wrapper'>
          <h3 className='section_title'>From Pharma industry</h3>
        </div>
        <Grid container spacing={deviceSm ? 2 : 4}>
          {articles.map((article: IArticle, key) => (
            <Grid key={key} item xs={12} md={6}>
              {key === 0 || (key === 1 && !deviceSm) ? null : (
                <Divider style={{ margin: deviceSm ? '0 0 1rem 0' : '0 0 2rem 0' }} />
              )}
              <ArticleCard article={article} displayGrid={true} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  ) : (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Divider style={{ margin: '0 0 2rem 0' }} />
      <Grid container spacing={deviceXs ? 2 : 4} justify='center'>
        <Grid item xs={12} md={9}>
          <ArticleCard article={articles[1]} displayGrid={displayGrid} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Pharma;
