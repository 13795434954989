import React, { useState } from 'react';
import Latest from './Latest';
import { ReactComponent as ChevronDownIcon } from '../../media/icons/chevron-down.svg';
import { ReactComponent as GridIcon } from '../../media/icons/grid.svg';
import { ReactComponent as ListIcon } from '../../media/icons/list.svg';
import { Link } from 'react-router-dom';
import { Container, Tab, Tabs } from '@material-ui/core';
import ArticlesScroll from './ArticlesScroll';
import FromExperts from './FromExperts';
import Studies from './Studies';
import Pharma from './Pharma';

interface Props {}

const Hub = (props: Props) => {
  const [currentTab, setCurrentTab] = useState<string>('all');
  const [displayGrid, setDisplayGrid] = useState<boolean>(true);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (displayGrid && newValue !== 'all') {
      let a = document.createElement('a');
      a.href = '#articles-scroll';
      a.click();
    }
    setCurrentTab(newValue);
  };

  return (
    <div>
      <div className='page-title-wrapper'>
        <h2 className='page-title'>Covid-19</h2>
        <Link to='/' className='home-page-button'>
          <ChevronDownIcon style={{ transform: 'rotate(90deg)' }} /> Home page
        </Link>
      </div>
      <div className='section__dark' style={{ padding: 0 }}>
        <Container maxWidth='lg'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tabs value={currentTab} onChange={handleTabChange} variant='scrollable' scrollButtons='off'>
              <Tab label='All' value='all' disableRipple />
              <Tab label='Diagnostic' value='diagnostic' disableRipple />
              <Tab label='Theraphy' value='therapy' disableRipple />
              <Tab label='Case reports' value='reports' disableRipple />
              <Tab label='Diseases' value='diseases' disableRipple />
              <Tab label='News' value='news' disableRipple />
              <Tab label='Info for MD' value='md' disableRipple />
              <Tab label='Info for patient' value='patient' disableRipple />
            </Tabs>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GridIcon
                className={`grid-button ${displayGrid ? 'grid-button__selected' : ''}`}
                onClick={() => setDisplayGrid(true)}
              />
              <ListIcon
                className={`grid-button ${displayGrid ? '' : 'grid-button__selected'}`}
                onClick={() => setDisplayGrid(false)}
              />
            </div>
          </div>
        </Container>
      </div>
      <Latest displayGrid={displayGrid} />
      <ArticlesScroll displayGrid={displayGrid} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <FromExperts displayGrid={displayGrid} />
      {displayGrid && <Studies />}
      <Pharma displayGrid={displayGrid} />
    </div>
  );
};

export default Hub;
